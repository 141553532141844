define("ember-animated/motions/resize", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resize;
  _exports.Resize = void 0;

  /**
    Smoothly resizes _sprite_ from its the initial size to its final size.
  
    _sprite_ must have both `initialBounds` and `finalBounds` set.
  
    Consider using `scale` instead because scale uses CSS transforms and will not trigger reflow.
  
    ```js
    for (let sprite of insertedSprites) {
      sprite.startAtSprite(beacons['source']);
      resize(sprite)
    }
    ```
  
    @function resize
    @export default
    @param {Sprite} sprite
    @return {Motion}
  */
  function resize(sprite, opts) {
    return new Resize(sprite, opts).run();
  }

  class Resize extends _emberAnimated.Motion {
    constructor(sprite, opts) {
      super(sprite, opts);
      this.prior = null;
      this.widthTween = null;
      this.heightTween = null;
    }

    interrupted(motions) {
      this.prior = motions.find(m => m instanceof this.constructor);
    }

    *animate() {
      var sprite = this.sprite;
      var duration = this.duration;

      if (!this.prior) {
        this.widthTween = new _emberAnimated.Tween(sprite.initialBounds.width / sprite.initialCumulativeTransform.a, sprite.finalBounds.width / sprite.finalCumulativeTransform.a, duration, this.opts.easing);
        this.heightTween = new _emberAnimated.Tween(sprite.initialBounds.height / sprite.initialCumulativeTransform.d, sprite.finalBounds.height / sprite.finalCumulativeTransform.d, duration, this.opts.easing);
      } else {
        this.widthTween = new _emberAnimated.Tween(0, sprite.finalBounds.width / sprite.finalCumulativeTransform.a - this.prior.sprite.finalBounds.width, duration, this.opts.easing).plus(this.prior.widthTween);
        this.heightTween = new _emberAnimated.Tween(0, sprite.finalBounds.height / sprite.finalCumulativeTransform.d - this.prior.sprite.finalBounds.height, duration, this.opts.easing).plus(this.prior.heightTween);
      }

      while (!this.widthTween.done || !this.heightTween.done) {
        sprite.applyStyles({
          width: this.widthTween.currentValue,
          height: this.heightTween.currentValue
        });
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.Resize = Resize;
});