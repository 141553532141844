define("bp-ember-components/components/x-notification/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span data-tooltip={{this.value}}>
    {{#if (eq this.value "ready")}}
      <i class="hourglass icon"></i>
    {{else if (eq this.value "scheduled")}}
      <i class="hourglass start icon"></i>
    {{else if (eq this.value "sent")}}
      <i class="check icon"></i>
    {{else if (eq this.value "error")}}
      <i class="bug icon"></i>
    {{else}}
      <i class="question circle outline icon"></i>
    {{/if}}
  </span>
  */
  {
    id: "4zugwdLl",
    block: "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"data-tooltip\",[23,0,[\"value\"]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"value\"]],\"ready\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"hourglass icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"value\"]],\"scheduled\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"hourglass start icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"value\"]],\"sent\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"check icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"value\"]],\"error\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"bug icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"question circle outline icon\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-notification/status.hbs"
    }
  });

  class Status extends Ember.Component {}

  _exports.default = Status;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Status);
});