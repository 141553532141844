define("ember-table/components/-private/simple-checkbox", ["exports", "ember-table/-private/utils/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['ariaLabel:aria-label', 'checked', 'disabled', 'indeterminate', 'type', 'value'],
    ariaLabel: undefined,
    checked: (0, _defaultTo.default)(false),
    disabled: (0, _defaultTo.default)(false),
    indeterminate: (0, _defaultTo.default)(false),
    onChange: null,
    onClick: null,
    type: 'checkbox',
    value: null,

    click(event) {
      this.sendAction('onClick', event);
    },

    change(event) {
      var checked = this.element.checked;
      var indeterminate = this.element.indeterminate;
      var value = this.get('value'); // Checked and indeterminate state have been changed, but that's not DDAU!
      // Reset the change, send the action and wait for it to be changed manually

      this.element.checked = this.get('checked');
      this.element.indeterminate = this.get('indeterminate');
      this.sendAction('onChange', checked, {
        value,
        indeterminate
      }, event);
    }

  });

  _exports.default = _default;
});