define("ember-animated/components/ea-list-element", ["exports", "ember-animated/-private/ember-internals"], function (_exports, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
     This component has one job: tracking which DOM elements correspond
     with which list elements.
  */
  var _default = Ember.Component.extend({
    tagName: '',
    isEmberAnimatedListElement: true,

    didRender() {
      var mapping = this.get('elementToChild');
      var child = this.get('child');

      this._forEachElement(elt => {
        mapping.set(elt, child);
      });
    },

    _forEachElement(fn) {
      var {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      var node = firstNode;

      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          fn(node);
        } else if (!/^\s*$/.test(node.textContent)) {
          (false && Ember.warn("Found bare text content inside an animator", false, {
            id: "ember-animated-bare-text"
          }));
        }

        if (node === lastNode) {
          break;
        }

        node = node.nextSibling;
      }
    }

  });

  _exports.default = _default;
});