define("ember-composable-helpers/utils/get-index", ["exports", "ember-composable-helpers/utils/is-equal"], function (_exports, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getIndex;

  function getIndex(array, currentValue, useDeepEqual) {
    var needle = currentValue;

    if (useDeepEqual) {
      needle = Ember.A(array).find(object => {
        return (0, _isEqual.default)(object, currentValue, useDeepEqual);
      });
    }

    var index = Ember.A(array).indexOf(needle);
    return index >= 0 ? index : null;
  }
});