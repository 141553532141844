define("ember-composable-helpers/-private/create-multi-array-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  var idForArray = array => "__array-".concat(Ember.guidFor(array));

  function _default(multiArrayComputed) {
    return Ember.Helper.extend({
      compute(_ref) {
        var [...arrays] = _ref;
        Ember.set(this, 'arrays', arrays.map(obj => {
          if (Ember.isArray(obj)) {
            return Ember.A(obj);
          }

          return obj;
        }));
        return Ember.get(this, 'content');
      },

      valuesDidChange: Ember.observer('arrays.[]', function () {
        this._recomputeArrayKeys();

        var arrays = Ember.get(this, 'arrays');
        var arrayKeys = Ember.get(this, 'arrayKeys');

        if (Ember.isEmpty(arrays)) {
          Ember.defineProperty(this, 'content', []);
          return;
        }

        Ember.defineProperty(this, 'content', multiArrayComputed(...arrayKeys));
      }),
      contentDidChange: Ember.observer('content.[]', function () {
        this.recompute();
      }),

      _recomputeArrayKeys() {
        var arrays = Ember.get(this, 'arrays');
        var oldArrayKeys = Ember.get(this, 'arrayKeys') || [];
        var newArrayKeys = arrays.map(idForArray);
        var keysToRemove = oldArrayKeys.filter(key => {
          return newArrayKeys.indexOf(key) === -1;
        });
        keysToRemove.forEach(key => Ember.set(this, key, null));
        arrays.forEach(array => Ember.set(this, idForArray(array), array));
        Ember.set(this, 'arrayKeys', newArrayKeys);
      }

    });
  }
});