define('ember-form-for/components/form-fields/text-field', ['exports', 'ember-form-for/templates/components/form-fields/text-field'], function (exports, _textField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const Component = Ember.Component,
        set = Ember.set;


  const TextFieldComponent = Component.extend({
    tagName: '',
    layout: _textField.default,

    control: 'one-way-text',

    update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  TextFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = TextFieldComponent;
});