define("ember-composable-helpers/helpers/range", ["exports", "ember-composable-helpers/utils/comparison"], function (_exports, _comparison) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.range = range;
  _exports.default = void 0;

  function range(_ref) {
    var [min, max, isInclusive] = _ref;
    isInclusive = Ember.typeOf(isInclusive) === 'boolean' ? isInclusive : false;
    var numbers = [];

    if (min < max) {
      var testFn = isInclusive ? _comparison.lte : _comparison.lt;

      for (var i = min; testFn(i, max); i++) {
        numbers.push(i);
      }
    }

    if (min > max) {
      var _testFn = isInclusive ? _comparison.gte : _comparison.gt;

      for (var _i = min; _testFn(_i, max); _i--) {
        numbers.push(_i);
      }
    }

    if (min === max && isInclusive) {
      numbers.push(max);
    }

    return numbers;
  }

  var _default = Ember.Helper.helper(range);

  _exports.default = _default;
});