define("ember-animated/motions/compensate-for-scale", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compensateForScale;
  _exports.CompensateForScale = void 0;

  function compensateForScale(sprite, opts) {
    return new CompensateForScale(sprite, opts).run();
  }

  class CompensateForScale extends _emberAnimated.Motion {
    constructor(sprite, opts) {
      super(sprite, opts);
      this.widthTween = null;
      this.heightTween = null;
    }

    *animate() {
      var sprite = this.sprite;
      var duration = this.duration;
      var widthFactor = sprite.finalCumulativeTransform.a / sprite.initialCumulativeTransform.a;
      var heightFactor = sprite.finalCumulativeTransform.d / sprite.initialCumulativeTransform.d;
      this.widthTween = new _emberAnimated.Tween(sprite.transform.a, sprite.transform.a * widthFactor, duration);
      this.heightTween = new _emberAnimated.Tween(sprite.transform.d, sprite.transform.d * heightFactor, duration);

      while (!this.widthTween.done || !this.heightTween.done) {
        sprite.scale(this.widthTween.currentValue / sprite.transform.a, this.heightTween.currentValue / sprite.transform.d);
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.CompensateForScale = CompensateForScale;
});