define('ember-form-for/mixins/form-field', ['exports', 'ember-form-for/utils/titlecase'], function (exports, _titlecase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const assert = Ember.assert,
        computed = Ember.computed,
        get = Ember.get,
        guidFor = Ember.guidFor,
        set = Ember.set,
        typeOf = Ember.typeOf,
        Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    instrumentDisplay: '{{form-field}}',

    didReceiveAttrs() {
      assert(`${this.instrumentDisplay} requires propertyName to be set`, typeOf(get(this, 'propertyName')) === 'string');

      let objectType = typeOf(get(this, 'object'));
      assert(`${this.instrumentDisplay} requires object to be set`, objectType === 'object' || objectType === 'instance');

      this._setupLabel();

      this._super(...arguments);
    },

    identifier: computed('object', 'propertyName', 'value', function () {
      return this._identifier();
    }),

    _identifier() {
      return `${guidFor(get(this, 'object'))}_${get(this, 'propertyName')}`;
    },

    _setupLabel() {
      set(this, 'label', get(this, 'label') || (0, _titlecase.default)(get(this, 'propertyName')));
    }
  });
});