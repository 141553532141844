define("ember-cli-adapter-pattern/mixins/adaptable", ["exports", "ember-cli-adapter-pattern/utils/required-method"], function (_exports, _requiredMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
     * A cache of active adapters to save
     * time on expensive container lookups.
     *
     * @type {Object}
     */
    _adapters: null,

    /*
     * Extra information you can attach to
     * every adapter call. This can be handy
     * when there is a value that needs to
     * be present with every aapter call,
     * reducing the need to pass the value
     * each time.
     *
     * @type {Object}
     */
    context: null,

    /*
     * Instantiates a series of adapters as
     * defined in the application config and
     * caches them to save on expensive future
     * lookups.
     *
     * @method activateAdapters
     *
     * @param {Array} adapterOptions
     *   Adapter configuration options.
     */
    activateAdapters(adapterOptions) {
      var cachedAdapters = Ember.get(this, '_adapters');
      var activatedAdapters = {};
      adapterOptions.forEach(adapterOption => {
        var {
          name
        } = adapterOption;
        var adapter = cachedAdapters[name] ? cachedAdapters[name] : this.activateAdapter(adapterOption);
        Ember.set(activatedAdapters, name, adapter);
      });
      Ember.set(this, '_adapters', activatedAdapters);
    },

    /*
     * Instantiates a single adapter from a
     * configuration object.
     *
     * @method activateAdapter
     *
     * @params {Object} adapterOptions
     *   Adapter configuration options. Must have a name property, and optioanlly a config property.
     *
     * @return {Object}
     *   An instantiated adpater.
     */
    activateAdapter() {
      var {
        name,
        config
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var adapter = this._lookupAdapter(name);

      (false && !(adapter) && Ember.assert("Could not find adapter ".concat(name), adapter));
      return adapter.create({
        adaptable: this,
        config
      });
    },

    /*
     * Invoke a method on a registered
     * adpater. If a specific adapter
     * name is supplied then the method
     * will only be invoked on that
     * adapter, providing it exists.
     *
     * @method invoke
     *
     * @param {String} methodName
     *   The name of the method to invoke.
     *
     * @param {Rest} args
     *   Any other supplied arguments.
     *
     * @return {Ember.RSVP}
     *   A hash of promise objects.
     */
    invoke(methodName) {
      var cachedAdapters = Ember.get(this, '_adapters');
      var adapterNames = Object.keys(cachedAdapters);
      var [selectedAdapterNames, options] = (arguments.length <= 1 ? 0 : arguments.length - 1) > 1 ? [[arguments.length <= 1 ? undefined : arguments[1]], arguments.length <= 2 ? undefined : arguments[2]] : [adapterNames, arguments.length <= 1 ? undefined : arguments[1]];
      var context = Ember.get(this, 'context');
      var mergedOptions = Ember.assign({}, context, options); // Store a promise for each adapter response.

      var promises = {};
      selectedAdapterNames.map(adapterName => {
        var adapter = Ember.get(cachedAdapters, adapterName);
        promises[adapterName] = Ember.RSVP.resolve(adapter[methodName].call(adapter, mergedOptions));
      });
      return Ember.RSVP.hash(promises);
    },

    /*
     * Ensure that we have a clean cache
     * of adapters. It may be beneficial
     * to override this method in a
     * consuming application or addon so
     * the adapters can be activated
     * here also.
     *
     * @method createAdapters
     * @on init
     */
    createAdapters: Ember.on('init', function () {
      Ember.set(this, '_adapters', {});
      Ember.set(this, 'context', {});
    }),

    /*
     * Tear down any cached adapters.
     *
     * @method destroyAdapters
     * @on willDestroy
     */
    destroyAdapters: Ember.on('willDestroy', function () {
      var cachedAdapters = Ember.get(this, '_adapters');

      for (var adapterName in cachedAdapters) {
        Ember.get(cachedAdapters, adapterName).destroy();
      }
    }),

    /*
     * An abstract method that needs to
     * be defined on the consuming
     * application or addon responsible
     * for the lookup of adapter objects
     * from the container.
     *
     * @method lookupAdapter
     * @private
     */
    _lookupAdapter: (0, _requiredMethod.default)('_lookupAdapter')
  });

  _exports.default = _default;
});