define("ember-safe-button/components/trigger", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Trigger is a component that is by default covered by safety and triggers the action passed to <SafeButton> when clicked.
  
    ```
    <SafeButton
      @onConfirm={{action safeButtonClicked}} as |button|
    >
      <button.trigger class="bg-red-dark" aria-label="Confirm item deletion">
        Trigger
      </button.trigger>
    </SafeButton>
    ```
  
    @class SafetyComponent
   */
  class TriggerComponent extends _component.default {
    focusMe(element) {
      element.focus();
    }

  }

  _exports.default = TriggerComponent;
});