define("ember-animated/components/animated-container", ["exports", "ember-animated/motions/resize", "ember-animated/-private/ember-scheduler", "ember-animated/-private/sprite", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/templates/components/animated-container"], function (_exports, _resize, _emberScheduler, _sprite, _emberAnimated, _emberInternals, _animatedContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Provides a boundary between animator components and the surrounding document
   which smoothly resizes as animators change. Use animated-container whenever you
   need to "hold a place for" some animated content while that content is animating.
    ```hbs
    <button {{action toggleThing}}>Toggle</button>
    <AnimatedContainer>
      {{#animated-if showThing use=transition }}
          <div class="message" {{action "toggleThing"}}>
              Hello!
          </div>
      {{/animated-if}}
    </AnimatedContainer>
    <p>
      This is outside of the container.
    </p>
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import {easeOut, easeIn } from 'ember-animated/easings/cosine';
  
    export default Component.extend({
      showThing: false,
  
      toggleThing() {
        this.set('showThing', !this.get('showThing'));
      },
  
      transition: function * ({ insertedSprites, keptSprites, removedSprites }) {
        for (let sprite of insertedSprites) {
          sprite.startAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeOut });
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeIn });
        }
      },
    });
    ```
    @class animated-container
    @public
  */
  var _default = Ember.Component.extend({
    layout: _animatedContainer.default,
    tagName: '',
    motionService: Ember.inject.service('-ea-motion'),

    /**
    * Use a custom tag for the container. Defaults to div.
     @argument tag
     @type String
    */
    tag: 'div',

    /**
    * Whether to animate the initial render. You will probably also need to set
    * initialInsertion=true on a child component of animated-container.
    * Defaults to false.
     @argument onInitialRender
     @type Boolean
    */
    onInitialRender: false,

    init() {
      this._super();

      this._signals = null;
      this._signalPromise = null;
      this._signalResolve = null;
      this._inserted = false;
      this._startingUp = false;
      this.maybeAnimate = this.maybeAnimate.bind(this);
      this.sprite = null;
      this.get('motionService').register(this).observeDescendantAnimations(this, this.maybeAnimate);
      (false && !(!this.class) && Ember.deprecate("passing a \"class\" argument to animated-container is deprecated. Switch to angle bracket invocation and pass an HTML attribute instead", !this.class, {
        id: 'ember-animated-container-class-arg',
        until: '1.0.0'
      }));
    },

    didInsertElement() {
      this._inserted = true;
    },

    _ownElement() {
      if (this._inserted) {
        return (0, _emberInternals.componentNodes)(this).firstNode;
      }
    },

    willDestroyElement() {
      this.get('motionService').unregister(this).unobserveDescendantAnimations(this, this.maybeAnimate);
    },

    isAnimating: Ember.computed.alias('animate.isRunning'),

    maybeAnimate(_ref) {
      var {
        duration,
        task
      } = _ref;

      if (!this._startingUp) {
        this.get('animate').perform(duration, task);
      }
    },

    beginStaticMeasurement() {
      if (this.sprite) {
        this.sprite.unlock();
      }
    },

    endStaticMeasurement() {
      if (this.sprite) {
        this.sprite.lock();
      }
    },

    animate: (0, _emberScheduler.task)(function* (duration, animationTask) {
      this._startingUp = true;
      var service = this.get('motionService');
      var sprite;
      var useMotion;

      var element = this._ownElement();

      if (element) {
        sprite = _sprite.default.sizedStartingAt(element);
        this.sprite = sprite;
        sprite.lock();
        useMotion = true;
      } else {
        useMotion = this.get('onInitialRender');
      }

      try {
        yield (0, _emberAnimated.afterRender)();
        yield (0, _emberAnimated.microwait)();
      } finally {
        this._startingUp = false;
      }

      yield* service.staticMeasurement(() => {
        if (!sprite) {
          sprite = _sprite.default.sizedEndingAt(this._ownElement());
          this.sprite = sprite;
        } else {
          sprite.measureFinalBounds();
        }
      });

      if (useMotion) {
        yield* new (this.motion || _resize.Resize)(this.sprite, {
          duration
        })._run();
      }

      yield animationTask;
      this.sprite.unlock();
      this.sprite = null;
    }).restartable()
  });

  _exports.default = _default;
});