define("ember-safe-button/templates/components/safety", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qB1zYfsP",
    "block": "{\"symbols\":[\"@displayedMessage\",\"&default\",\"@onTrigger\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"onclick\",[28,\"perform\",[[23,3,[]]],null]],[12,\"class\",\"ember-safe-button-safety\"],[12,\"aria-label\",\"Deactivate safety\"],[13,4],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-safe-button/templates/components/safety.hbs"
    }
  });

  _exports.default = _default;
});