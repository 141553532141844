define("ember-cli-analytics/integrations/facebook", ["exports", "ember-cli-analytics/integrations/base", "ember-cli-analytics/utils/can-use-dom", "ember-cli-analytics/utils/without"], function (_exports, _base, _canUseDom, _without) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     */
    trackPage() {
      if (_canUseDom.default) {
        window.fbq('track', 'PageView');
      }
    },

    /*
     * Send an arbitrary event to the
     * anlytics engine.
     *
     * @method trackEvent
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var {
        event
      } = options;
      var properties = (0, _without.default)(options, 'event');
      (false && !(event) && Ember.assert('You must pass an event name', event));

      if (_canUseDom.default) {
        if (Ember.isPresent(Object.keys(properties))) {
          window.fbq('track', event, properties);
        } else {
          window.fbq('track', event);
        }
      }
    },

    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackConversion() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.trackEvent(options);
    },

    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: Ember.on('init', function () {
      var config = Ember.get(this, 'config');
      var {
        id
      } = Ember.assign({}, config);
      (false && !(id) && Ember.assert('You must pass a valid `id` to the Bing adapter', id));

      if (!_canUseDom.default) {
        return;
      }

      if (!window.fbq) {
        /* eslint-disable */
        (function (f, b, e, v, n, t, s) {
          if (f.fbq) return;

          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };

          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');
        /* eslint-enable */

      }

      window.fbq('init', id);
    }),

    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: Ember.on('willDestroy', function () {
      if (_canUseDom.default) {
        Ember.$('script[src*="facebook"]').remove();
        delete window.fbq;
      }
    })
  });

  _exports.default = _default;
});