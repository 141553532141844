define("ember-changeset/helpers/changeset", ["exports", "ember-changeset", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-promise"], function (_exports, _emberChangeset, _isChangeset, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function changeset(_ref) {
    var [obj, validations] = _ref;
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }

    if ((0, _isPromise.default)(obj)) {
      return Promise.resolve(obj).then(resolved => new _emberChangeset.default(resolved, validations, {}, options));
    }

    return new _emberChangeset.default(obj, validations, {}, options);
  }

  var _default = Ember.Helper.helper(changeset);

  _exports.default = _default;
});