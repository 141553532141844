define("ember-validated-form/components/validated-label", ["exports", "ember-validated-form/templates/components/validated-label"], function (_exports, _validatedLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component generates default labels.
   *
   * It will be used when label parameter is specified.
   * {{validated-input label="Name default"}} (Will render label next to input field.)
   *
   * or it is possible to override it with a custom component:
   * {{validated-input label="Name custom"
   *   labelComponent=(component "some-custom-component"  customComponentParam="Custom text for custom component.")}}
   */
  var _default = Ember.Component.extend({
    layout: _validatedLabel.default,
    tagName: "label",
    attributeBindings: ["inputId:for"],
    classNameBindings: ["config.css.label"],
    requiredLabel: Ember.computed("config", function () {
      return this.get("config.label.required") || "*";
    })
  });

  _exports.default = _default;
});