define("ember-safe-button/components/safe-button", ["exports", "@glimmer/component", "ember-animated/transitions/move-over", "ember-concurrency"], function (_exports, _component, _moveOver, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var DEFAULT_TIMEOUT = 3000;
  /**
    Main component.
  
    ```
    <SafeButton
      class="border-black"
      @message="Delete me"
      @timeout={{2000}}
      @onConfirm={{action safeButtonClicked}} />
    ```
  
    @class SafeButtonComponent
    @param {string} [class="ember-safe-button"] CSS class to be applied on the wrapping element.
    @param {number} [timeout=3000] Number of millisenconds after which the safety rolls back over the trigger.
    @param {string} [message="delete"] Text to be printed on the buttons in case block is not provided.
    @param {function} onConfirm - Action to trigger whenever user clicks the trigger.
   */

  var SafeButtonComponent = (_dec = (0, _emberConcurrency.task)(function* () {
    this.safetyOn = false;
    yield (0, _emberConcurrency.timeout)(this.timeout);
    this.safetyOn = true;
  }), (_class = (_temp = class SafeButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "safetyOn", _descriptor, this);

      _initializerDefineProperty(this, "triggerSafety", _descriptor2, this);
    }

    get timeout() {
      return this.args.timeout || DEFAULT_TIMEOUT;
    }

    get displayedMessage() {
      return this.args.message || "delete";
    }

    rules(_ref) {
      var {
        newItems
      } = _ref;

      if (newItems[0]) {
        return _moveOver.toRight;
      } else {
        return _moveOver.toLeft;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "safetyOn", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "triggerSafety", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SafeButtonComponent;
});