define("bp-ember-components/components/data-grid/supply/is-saving", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.item.isSaving}}
    <i class="spinner loading icon"></i>
  {{else}}
    {{#if this.value}}
      <XDate::Long @value={{this.value}} />
    {{else}}
      {{#if this.item.item}}
        <i class="disabled save icon"></i>
      {{else}}
        <i class="disabled hand point left icon"></i>
      {{/if}}
    {{/if}}
  {{/if}}
  */
  {
    id: "V2gMxrV7",
    block: "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"item\",\"isSaving\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"spinner loading icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[5,\"x-date/long\",[],[[\"@value\"],[[23,0,[\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"item\",\"item\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"disabled save icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"disabled hand point left icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/data-grid/supply/is-saving.hbs"
    }
  });

  var IsSaving = (_dec = (0, _component.tagName)(""), _dec(_class = class IsSaving extends Ember.Component {}) || _class);
  _exports.default = IsSaving;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, IsSaving);
});