define('ember-form-for/components/form-controls/reset', ['exports', 'ember-form-for/components/form-controls/button'], function (exports, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _button.default.extend({
    type: 'reset',

    click(e, ...args) {
      e.preventDefault();
      if (this.get('reset') !== undefined) {
        this.get('reset')(...args);
      }
    }
  });
});