define("bp-ember-components/components/safe-delete", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SafeButton @onConfirm={{@onConfirm}} @timeout={{5000}} as |button|>
    <button.safety type="button" class="ui icon button">
      <i class="trash icon"></i>
    </button.safety>
    <button.trigger type="button" class="ui negative icon button">
      <i class="trash icon"></i>
    </button.trigger>
  </SafeButton>
  */
  {
    id: "LYPsg/AH",
    block: "{\"symbols\":[\"button\",\"@onConfirm\"],\"statements\":[[5,\"safe-button\",[],[[\"@onConfirm\",\"@timeout\"],[[23,2,[]],5000]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"safety\"]],[[12,\"class\",\"ui icon button\"],[12,\"type\",\"button\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"trash icon\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"trigger\"]],[[12,\"class\",\"ui negative icon button\"],[12,\"type\",\"button\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"trash icon\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/safe-delete.hbs"
    }
  });

  var SafeDelete = (_dec = (0, _component.tagName)(""), _dec(_class = class SafeDelete extends Ember.Component {}) || _class);
  _exports.default = SafeDelete;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SafeDelete);
});