define("ember-composable-helpers/helpers/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var groupFunction = function groupFunction() {
    var array = Ember.get(this, 'array');
    var byPath = Ember.get(this, 'byPath');
    var groups = Ember.Object.create();
    array.forEach(item => {
      var groupName = Ember.get(item, byPath);
      var group = Ember.get(groups, groupName);

      if (!Ember.isArray(group)) {
        group = Ember.A();
        groups["".concat(groupName)] = group;
      }

      group.push(item);
    });
    return groups;
  };

  var _default = Ember.Helper.extend({
    compute(_ref) {
      var [byPath, array] = _ref;
      Ember.set(this, 'array', array);
      Ember.set(this, 'byPath', byPath);
      return Ember.get(this, 'content');
    },

    byPathDidChange: Ember.observer('byPath', function () {
      var byPath = Ember.get(this, 'byPath');

      if (byPath) {
        Ember.defineProperty(this, 'content', Ember.computed("array.@each.".concat(byPath), groupFunction));
      } else {
        Ember.defineProperty(this, 'content', null);
      }
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});