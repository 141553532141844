define("ember-cli-adapter-pattern/utils/proxy-to-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = proxyToAdapter;

  /*
   * Utility method, returning a proxy function
   * that looks up a function on the intended
   * adapter. The proxy funtion will return a
   * resolved promise with a value.
   *
   * @method proxyToAdapter
   *
   * @param {String} methodName
   *   Name of the method to proxy.
   *
   * @return {Function}
   *   A proxy function returning a resolved promise.
   */
  function proxyToAdapter(methodName) {
    (false && !(methodName) && Ember.assert('Method name is required for proxyToAdapter.', methodName));
    return function () {
      if (!this.invoke && typeof this.invoke !== 'function') {
        throw new Ember.Error('No invoke method. Have you forgotten to include the Adaptable mixin?');
      }

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return this.invoke(methodName, ...args);
    };
  }
});