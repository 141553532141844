define('ember-form-for/components/form-label', ['exports', 'ember-form-for/templates/components/form-label'], function (exports, _formLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const Component = Ember.Component;


  const FormLabelComponent = Component.extend({
    tagName: 'label',
    layout: _formLabel.default,

    attributeBindings: ['for', 'form']
  });

  FormLabelComponent.reopenClass({
    positionalParams: ['label']
  });

  exports.default = FormLabelComponent;
});