define("ember-animated/-private/ember-internals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentNodes = componentNodes;
  _exports.keyForArray = keyForArray;
  var {
    getViewBounds
  } = Ember.ViewUtils;

  function componentNodes(view) {
    var bounds = getViewBounds(view);
    return {
      firstNode: bounds.firstNode,
      lastNode: bounds.lastNode
    };
  }

  function keyForArray(keyPath) {
    switch (keyPath) {
      case '@index':
        return index;

      case '@identity':
      case undefined:
      case null:
        return identity;

      default:
        return item => Ember.get(item, keyPath);
    }
  }

  function index(item, index) {
    return String(index);
  }

  function identity(item) {
    switch (typeof item) {
      case 'string':
      case 'number':
        return String(item);

      default:
        return Ember.guidFor(item);
    }
  }
});