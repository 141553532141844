define("ember-validated-form/templates/components/validated-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ex0khpuC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"model\",\"loading\",\"input\",\"submit\"],[[24,[\"model\"]],[24,[\"loading\"]],[28,\"component\",[\"validated-input\"],[[\"model\",\"submitted\",\"validateBeforeSubmit\",\"config\"],[[24,[\"model\"]],[24,[\"submitted\"]],[24,[\"validateBeforeSubmit\"]],[24,[\"config\"]]]]],[28,\"component\",[\"validated-button\"],[[\"class\",\"type\",\"loading\",\"label\",\"config\"],[[24,[\"submitClass\"]],\"submit\",[24,[\"loading\"]],[24,[\"_submitLabel\"]],[24,[\"config\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-validated-form/templates/components/validated-form.hbs"
    }
  });

  _exports.default = _default;
});