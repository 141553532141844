define("bp-ember-components/components/data-grid/cashbook/kind", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.item.kind "visit")}}
    {{#if (eq this.session.data.authenticated.role "admin")}}
      <LinkTo @route="admin.visit" @model={{this.item.visit.id}}>
        {{t "cashbook.isVisit"}}
      </LinkTo>
    {{/if}}
  {{else if (eq this.item.kind "manual")}}
    <SafeDelete @onConfirm={{action this.onClick this.item}} />
  {{/if}}
  */
  {
    id: "meXIpV72",
    block: "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"item\",\"kind\"]],\"visit\"],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"session\",\"data\",\"authenticated\",\"role\"]],\"admin\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.visit\",[23,0,[\"item\",\"visit\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"cashbook.isVisit\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"item\",\"kind\"]],\"manual\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"safe-delete\",[],[[\"@onConfirm\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onClick\"]],[23,0,[\"item\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/data-grid/cashbook/kind.hbs"
    }
  });

  var Kind = (_dec = (0, _component.tagName)(""), _dec(_class = (_class2 = (_temp = class Kind extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = Kind;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Kind);
});