define('ember-load/components/ember-load-remover', ['exports', 'ember-load/templates/components/ember-load-remover'], function (exports, _emberLoadRemover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const inject = Ember.inject,
        Component = Ember.Component,
        $ = Ember.$;
  exports.default = Component.extend({
    layout: _emberLoadRemover.default,
    'ember-load-config': inject.service(),
    didInsertElement() {
      this._super(...arguments);
      this.removeLoadingIndicator();
    },
    /**
     * remove the loading indicator. By default this
     * removes the first element with the '.ember-load-indicator'
     * found CSS class from the DOM
     * @public
     */
    removeLoadingIndicator() {
      let loadingIndicatorClass = this.get('ember-load-config.loadingIndicatorClass') || 'ember-load-indicator';
      $(`.${loadingIndicatorClass}`).remove();
    }
  });
});