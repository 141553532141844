define("ember-table/components/-private/row-wrapper", ["exports", "ember-table/-private/utils/array", "ember-table/-private/utils/computed"], function (_exports, _array, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CellWrapper = Ember.Object.extend({
    cellValue: (0, _computed.dynamicAlias)('rowValue', 'columnValue.valuePath'),
    cellMeta: Ember.computed('rowMeta', 'columnValue', function () {
      var rowMeta = Ember.get(this, 'rowMeta');
      var columnValue = Ember.get(this, 'columnValue');

      if (!rowMeta._cellMetaCache.has(columnValue)) {
        rowMeta._cellMetaCache.set(columnValue, Ember.Object.create());
      }

      return rowMeta._cellMetaCache.get(columnValue);
    })
  });
  var layout = Ember.HTMLBars.template({
    "id": "Z3RDUihD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"api\"]]]]],\"hasEval\":false}",
    "meta": {}
  });

  var _default = Ember.Component.extend({
    layout,
    tagName: '',
    canSelect: undefined,
    checkboxSelectionMode: undefined,
    columnMetaCache: undefined,
    columns: undefined,
    rowMetaCache: undefined,
    rowSelectionMode: undefined,
    rowValue: undefined,

    init() {
      this._super(...arguments);

      this._cells = Ember.A([]);
    },

    destroy() {
      this._cells.forEach(cell => cell.destroy());

      this._super(...arguments);
    },

    api: Ember.computed('rowValue', 'rowMeta', 'cells', 'canSelect', 'rowSelectionMode', function () {
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      var cells = this.get('cells');
      var canSelect = this.get('canSelect');
      var rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      return {
        rowValue,
        rowMeta,
        cells,
        rowSelectionMode
      };
    }),
    rowMeta: Ember.computed('rowValue', function () {
      var rowValue = this.get('rowValue');
      var rowMetaCache = this.get('rowMetaCache');
      return rowMetaCache.get(rowValue);
    }),
    cells: Ember.computed('rowValue', 'rowMeta', 'columns.[]', 'canSelect', 'checkboxSelectionMode', 'rowSelectionMode', function () {
      var columns = this.get('columns');
      var numColumns = Ember.get(columns, 'length');
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      var canSelect = this.get('canSelect');
      var checkboxSelectionMode = canSelect ? this.get('checkboxSelectionMode') : 'none';
      var rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      var {
        _cells
      } = this;

      if (numColumns !== _cells.length) {
        while (_cells.length < numColumns) {
          _cells.pushObject(CellWrapper.create());
        }

        while (_cells.length > numColumns) {
          _cells.popObject().destroy();
        }
      }

      _cells.forEach((cell, i) => {
        var columnValue = (0, _array.objectAt)(columns, i);
        var columnMeta = this.get('columnMetaCache').get(columnValue); // eslint-disable-next-line ember/no-side-effects, ember-best-practices/no-side-effect-cp

        Ember.setProperties(cell, {
          checkboxSelectionMode,
          columnMeta,
          columnValue,
          rowMeta,
          rowSelectionMode,
          rowValue
        });
      });

      return _cells;
    })
  });

  _exports.default = _default;
});