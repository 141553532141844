define("bp-ember-components/components/eet-state", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiPopup @html={{this.message}}>
    <i class={{this.icon}}></i>
  </UiPopup>
  */
  {
    id: "XekYpLSi",
    block: "{\"symbols\":[],\"statements\":[[5,\"ui-popup\",[],[[\"@html\"],[[23,0,[\"message\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[23,0,[\"icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/eet-state.hbs"
    }
  });

  var STATUS_ICONS = {
    not_needed: "circle",
    sent: "check",
    will_send: "hourglass",
    failed: "exclamation"
  };
  var STATUS_COLORS = {
    not_needed: "green",
    sent: "green",
    will_send: "grey",
    failed: "red"
  };
  var EetState = (_dec = (0, _component.tagName)(""), _dec2 = Ember.computed.oneWay("value.eetState"), _dec3 = Ember.computed.oneWay("value.eetFik"), _dec4 = Ember.computed.oneWay("value.eetBkp"), _dec5 = Ember.computed("value.eetResponse"), _dec6 = Ember.computed("state", "color"), _dec7 = Ember.computed("state"), _dec8 = Ember.computed("state", "fik", "bkp", "response"), _dec(_class = (_class2 = (_temp = class EetState extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tooltipPosition", "left center");

      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "fik", _descriptor2, this);

      _initializerDefineProperty(this, "bkp", _descriptor3, this);
    }

    get response() {
      return JSON.stringify(this.value.eetResponse);
    }

    get icon() {
      var statusIcon = STATUS_ICONS[this.state];
      return "circular ".concat(statusIcon, " ").concat(this.color, " icon");
    }

    get color() {
      return STATUS_COLORS[this.state];
    }

    get message() {
      return "<dl><dt>status:</dt><dd>\n      ".concat(this.state, "</dd>\n    <dt>response:</dt><dd>").concat(this.response, "</dd><dt>fik</dt><dd>").concat(this.fik, "</dd><dt>bkp</dt><dd>").concat(this.bkp, "</dd>");
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fik", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "bkp", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "response", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "response"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "icon", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "icon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "color", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "color"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "message", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "message"), _class2.prototype)), _class2)) || _class);
  _exports.default = EetState;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EetState);
});