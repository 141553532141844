define("ember-animated/services/motion", ["exports", "ember-animated/-private/ember-scheduler", "ember-animated"], function (_exports, _emberScheduler, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MotionService = Ember.Service.extend({
    init() {
      this._super();

      this._rendezvous = [];
      this._measurements = [];
      this._animators = Ember.A();
      this._orphanObserver = null;
      this._animationObservers = [];
      this._descendantObservers = [];
      this._ancestorObservers = new WeakMap();
      this._beacons = null;
    },

    // === Notification System ===
    // Ever animator should register and unregister itself so we know
    // when there are any animations running. Animators are required to
    // have:
    //    - an isAnimating property
    //    - beginStaticMeasurement and endStaticMeasurement methods
    register(animator) {
      this._animators.pushObject(animator);

      return this;
    },

    unregister(animator) {
      this._animators.removeObject(animator);

      return this;
    },

    // Register to receive any sprites that are orphaned by a destroyed
    // animator.
    observeOrphans(fn) {
      if (this._orphanObserver) {
        throw new Error("Only one animated-orphans component can be used at one time");
      }

      this._orphanObserver = fn;
      return this;
    },

    unobserveOrphans(fn) {
      if (this._orphanObserver === fn) {
        this._orphanObserver = null;
      }

      return this;
    },

    // Register to know when an animation is starting anywhere in the app.
    observeAnimations(fn) {
      this._animationObservers.push(fn);

      return this;
    },

    unobserveAnimations(fn) {
      var index = this._animationObservers.indexOf(fn);

      if (index !== -1) {
        this._animationObservers.splice(index, 1);
      }

      return this;
    },

    // Register to know when an animation is starting within the
    // descendants of the given component
    observeDescendantAnimations(component, fn) {
      this._descendantObservers.push({
        component,
        fn
      });

      return this;
    },

    unobserveDescendantAnimations(component, fn) {
      var entry = this._descendantObservers.find(e => e.component === component && e.fn === fn);

      if (entry) {
        this._descendantObservers.splice(this._descendantObservers.indexOf(entry), 1);
      }

      return this;
    },

    // Register to know when an animation is starting among the
    // ancestors of the given component. The fn will be told whether
    // component is going to be destroyed or not at the end of the
    // animation.
    observeAncestorAnimations(component, fn) {
      var id;

      for (var ancestorComponent of ancestorsOf(component)) {
        // when we find an animated list element, we save its ID
        if (ancestorComponent.isEmberAnimatedListElement) {
          id = ancestorComponent.get('child.id');
        } else if (id != null) {
          // if we found an ID on the last loop, now we've got the list
          // element's parent which is the actual animator.
          var observers = this._ancestorObservers.get(ancestorComponent);

          if (!observers) {
            this._ancestorObservers.set(ancestorComponent, observers = new Map());
          }

          observers.set(fn, id);
          id = null;
        }
      }

      return this;
    },

    unobserveAncestorAnimations(component, fn) {
      for (var ancestorComponent of ancestorsOf(component)) {
        var observers = this._ancestorObservers.get(ancestorComponent);

        if (observers) {
          observers.delete(fn);
        }
      }

      return this;
    },

    // This is a publicly visible property you can use to know if any
    // animations are running. It's timing is deliberately not
    // synchronous, so that you can bind it into a template without
    // getting double-render errors.
    isAnimating: Ember.computed(function () {
      return this.get('isAnimatingSync');
    }),
    // Synchronously updated version of isAnimating. If you try to
    // depend on this in a template you will get double-render errors
    // (because the act of rendering can cause animations to begin).
    isAnimatingSync: Ember.computed('_animators.@each.isAnimating', function () {
      return this.get('_animators').any(animator => animator.get('isAnimating'));
    }),
    // Invalidation support for isAnimating
    _invalidateIsAnimating: (0, _emberScheduler.task)(function* () {
      yield (0, _emberAnimated.rAF)();
      this.notifyPropertyChange('isAnimating');
    }).observes('isAnimatingSync'),
    waitUntilIdle: (0, _emberScheduler.task)(function* () {
      // we are idle if we experience two frames in a row with nothing
      // animating.
      while (true) {
        yield (0, _emberAnimated.rAF)();

        if (!this.get('isAnimatingSync')) {
          yield (0, _emberAnimated.rAF)();

          if (!this.get('isAnimatingSync')) {
            return;
          }
        }
      }
    }),

    matchDestroyed(removed, transition, duration, shouldAnimateRemoved) {
      if (this._orphanObserver && removed.length > 0) {
        // if these orphaned sprites may be capable of animating,
        // delegate them to the orphanObserver. It will do farMatching
        // for them.
        this._orphanObserver(removed, transition, duration, shouldAnimateRemoved);
      } else {
        // otherwise, we make them available for far matching but they
        // can't be animated.
        this.get('farMatch').perform(null, [], [], removed, true);
      }
    },

    addBeacon: (0, _emberScheduler.task)(function* (name, beacon) {
      if (!this._beacons) {
        this._beacons = {};
      }

      if (this._beacons[name]) {
        throw new Error("There is more than one beacon named", name);
      }

      this._beacons[name] = beacon; // allows other farMatches to start

      yield (0, _emberAnimated.microwait)(); // allows other farMatches to finish

      yield (0, _emberAnimated.microwait)();
      this._beacons = null;
    }),
    farMatch: (0, _emberScheduler.task)(function* (runAnimationTask, inserted, kept, removed) {
      var longWait = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var matches = new Map();
      var mine = {
        inserted,
        kept,
        removed,
        matches,
        runAnimationTask,
        otherTasks: new Map()
      };

      this._rendezvous.push(mine);

      yield (0, _emberAnimated.microwait)();

      if (longWait) {
        // used by matchDestroyed because it gets called earlier in the
        // render cycle, so it needs to linger longer in order to
        // coincide with other farMatches.
        yield (0, _emberAnimated.afterRender)();
        yield (0, _emberAnimated.microwait)();
        yield (0, _emberAnimated.microwait)();
      }

      if (this.get('farMatch.concurrency') > 1) {
        this._rendezvous.forEach(target => {
          if (target === mine) {
            return;
          }

          performMatches(mine, target);
          performMatches(target, mine);
        });
      }

      this._rendezvous.splice(this._rendezvous.indexOf(mine), 1);

      return {
        farMatches: matches,
        matchingAnimatorsFinished: (0, _emberAnimated.allSettled)([...mine.otherTasks.keys()]),
        beacons: this._beacons
      };
    }),

    willAnimate(_ref) {
      var {
        task,
        duration,
        component,
        children
      } = _ref;
      var message = {
        task,
        duration
      }; // tell any of our ancestors who are observing their descendants

      var ancestors = [...ancestorsOf(component)];

      for (var _ref2 of this._descendantObservers) {
        var {
          component: observingComponent,
          fn
        } = _ref2;

        if (ancestors.indexOf(observingComponent) !== -1) {
          fn(message);
        }
      } // tell any of our descendants who are observing their ancestors


      var observers = this._ancestorObservers.get(component);

      if (observers) {
        var _loop = function _loop(id, _fn) {
          var child = children.find(child => child.id === id);

          if (child) {
            _fn(child.state);
          } // the else case here applies to descendants that are about
          // to be unrendered (not animated away -- immediately
          // dropped). They will still have an opportunity to animate
          // too, but they do it via their own willDestroyElement
          // hook, not the this early-warning hook.

        };

        for (var [_fn, id] of observers.entries()) {
          _loop(id, _fn);
        }
      } // tell anybody who is listening for all animations


      for (var _fn2 of this._animationObservers) {
        _fn2(message);
      }
    },

    staticMeasurement: function* staticMeasurement(fn) {
      var measurement = {
        fn,
        resolved: false,
        value: null
      };

      this._measurements.push(measurement);

      try {
        // allow all concurrent animators to join in with our single
        // measurement step instead of having each trigger its own reflow.
        yield (0, _emberAnimated.microwait)();

        if (!measurement.resolved) {
          // we are the first concurrent task to wake up, so we do the
          // actual resolution for everyone.
          var animators = this.get('_animators');
          animators.forEach(animator => animator.beginStaticMeasurement());

          this._measurements.forEach(m => {
            try {
              m.value = m.fn();
            } catch (err) {
              setTimeout(function () {
                throw err;
              }, 0);
            }

            m.resolved = true;
          });

          animators.forEach(animator => animator.endStaticMeasurement());
        }

        return measurement.value;
      } finally {
        this._measurements.splice(this._measurements.indexOf(measurement), 1);
      }
    }
  });

  function performMatches(sink, source) {
    sink.inserted.concat(sink.kept).forEach(sprite => {
      var match = source.removed.find(mySprite => sprite.owner.group == mySprite.owner.group && sprite.owner.id === mySprite.owner.id);

      if (match) {
        sink.matches.set(sprite, match);
        sink.otherTasks.set(source.runAnimationTask, true);
        source.matches.set(match, sprite);
        source.otherTasks.set(sink.runAnimationTask, true);
      }
    });
  }

  function* ancestorsOf(component) {
    var pointer = component.parentView;

    while (pointer) {
      yield pointer;
      pointer = pointer.parentView;
    }
  }

  var _default = MotionService;
  _exports.default = _default;
});