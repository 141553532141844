define("ember-safe-button/templates/components/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIDDCpUr",
    "block": "{\"symbols\":[\"@displayedMessage\",\"&default\",\"@onConfirm\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"ember-safe-button-trigger\"],[12,\"onclick\",[23,3,[]]],[12,\"aria-label\",\"Confirm action\"],[13,4],[3,\"did-insert\",[[23,0,[\"focusMe\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-safe-button/templates/components/trigger.hbs"
    }
  });

  _exports.default = _default;
});