define('ember-form-for/utils/date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  const abs = Math.abs,
        ceil = Math.ceil;
  const pad = exports.pad = (number, amount = 2) => {
    let padding = new Array(amount).join('0');
    return `${padding}${number}`.slice(-amount);
  };

  const toMonthString = exports.toMonthString = date => `${date.getFullYear()}-${pad(date.getMonth() + 1)}`;

  const toWeekString = exports.toWeekString = date => {
    date = new Date(+date);
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));

    let weekNumber = ceil(((date - new Date(date.getFullYear(), 0, 1)) / 8.64e7 + 1) / 7);

    return `${date.getFullYear()}-W${weekNumber}`;
  };

  const fromWeekString = exports.fromWeekString = string => {
    var _string$match$slice = string.match(/^(\d{4})-W(\d{1,2})$/).slice(1),
        _string$match$slice2 = _slicedToArray(_string$match$slice, 2);

    let year = _string$match$slice2[0],
        weekNr = _string$match$slice2[1];

    let jan1OfYear = new Date(Number(year), 0, 1);
    let jan4OfYear = new Date(Number(year), 0, 4);
    let correction = (jan4OfYear.getDay() || 7) + 4;

    let offsetFrom1Jan = (Number(weekNr) * 7 + 1 - correction) * 8.64e7;
    let result = new Date(+jan1OfYear + offsetFrom1Jan);

    result.setHours(0, 0, 0);

    return result;
  };

  const toDateString = exports.toDateString = date => `${toMonthString(date)}-${pad(date.getDate())}`;

  const toTimeString = exports.toTimeString = date => `${pad(date.getHours())}:${pad(date.getMinutes())}`;

  const toDatetimeLocalString = exports.toDatetimeLocalString = date => `${toDateString(date)}T${toTimeString(date)}`;

  const formatTimeZoneOffset = exports.formatTimeZoneOffset = offset => {
    if (offset === 0) {
      offset = 'Z';
    } else {
      let hours = abs(offset) / 60;
      let minutes = abs(offset) % 60;
      let sign = offset > 0 ? '-' : '+';
      offset = `${sign}${pad(hours)}:${pad(minutes)}`;
    }

    return offset;
  };
});