define("liquid-fire/helpers/lf-lock-model", ["exports", "liquid-fire/ember-internals"], function (_exports, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lfLockModel = lfLockModel;
  _exports.default = void 0;

  function lfLockModel(_ref) {
    var [routeInfo, outletName] = _ref;
    // ensures that the name is locked, see implementation of `routeModel`
    (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(routeInfo, outletName));
    return routeInfo;
  }

  var _default = Ember.Helper.helper(lfLockModel);

  _exports.default = _default;
});