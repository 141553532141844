define("liquid-fire/components/illiquid-model", ["exports", "liquid-fire/templates/components/illiquid-model"], function (_exports, _illiquidModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IlliquidModel = Ember.Component.extend({
    layout: _illiquidModel.default,
    tagName: '',

    didReceiveAttrs() {
      if (!this.get('_fixedModel')) {
        this.set('_fixedModel', this.get('model'));
      }
    }

  });
  IlliquidModel.reopenClass({
    positionalParams: ['model']
  });
  var _default = IlliquidModel;
  _exports.default = _default;
});