define("ember-animated/components/animated-beacon", ["exports", "ember-animated/templates/components/animated-beacon", "ember-animated/-private/ember-scheduler", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/-private/sprite"], function (_exports, _animatedBeacon, _emberScheduler, _emberAnimated, _emberInternals, _sprite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A component that marks a region of the page that
    can serve as a source or destination for sprites to animate to and from.
  
    ```hbs
    {{#animated-beacon name="one"}}
      <button {{action "launch"}}>Launch</button>
    {{/animated-beacon}}
  
    {{#animated-if showThing use=transition}}
      <div class="message" {{action "dismiss"}}>
        Hello
      </div>
    {{/animated-if}}
    ```
  
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import scale from 'ember-animated/motions/scale';
  
    export default Component.extend({
      showThing: false,
  
      transition: function *({ insertedSprites, keptSprites, removedSprites, beacons }) {
        for (let sprite of insertedSprites) {
          sprite.startAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
      });
    },
  
      actions: {
        launch() {
          this.set('showThing', true);
        },
        dismiss() {
          this.set('showThing', false);
        }
      }
    });
    ```
    @class animated-beacon
    @public
  */
  var _default = Ember.Component.extend({
    layout: _animatedBeacon.default,
    motionService: Ember.inject.service('-ea-motion'),
    tagName: '',

    init() {
      this._super();

      this.inserted = false;
    },

    didInsertElement() {
      this._super();

      this._inserted = true;
      this.animationStarting = this.animationStarting.bind(this);
      this.get('motionService').observeAnimations(this.animationStarting);
    },

    willDestroyElement() {
      this._super();

      this.get('motionService').unobserveAnimations(this.animationStarting);
    },

    animationStarting() {
      this.get('participate').perform();
    },

    _firstChildElement() {
      if (!this._inserted) {
        return;
      }

      var {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      var node = firstNode;

      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          return node;
        }

        if (node === lastNode) {
          break;
        }

        node = node.nextSibling;
      }
    },

    participate: (0, _emberScheduler.task)(function* () {
      var element = this._firstChildElement();

      if (!element) {
        return;
      }

      var offsetParent = _sprite.default.offsetParentStartingAt(element);

      var sprite = _sprite.default.positionedStartingAt(element, offsetParent);

      yield (0, _emberAnimated.afterRender)();
      yield (0, _emberAnimated.microwait)();
      yield* this.get('motionService').staticMeasurement(() => {
        offsetParent.measureFinalBounds();
        sprite.measureFinalBounds();
      });
      yield this.get('motionService.addBeacon').perform(this.name, sprite);
    }).restartable()
  });

  _exports.default = _default;
});