define("ember-composable-helpers/helpers/compute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compute = compute;
  _exports.default = void 0;

  function compute(_ref) {
    var [action, ...params] = _ref;
    return action(...params);
  }

  var _default = Ember.Helper.helper(compute);

  _exports.default = _default;
});