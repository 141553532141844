define('ember-form-for/helpers/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  const emberArray = Ember.A,
        helper = Ember.Helper.helper,
        isArray = Ember.isArray;
  function contains([haystack, needle]) {
    if (isArray(haystack)) {
      return emberArray(haystack).includes(needle);
    } else {
      return haystack === needle;
    }
  }

  exports.default = helper(contains);
});