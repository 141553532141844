define('ember-form-for/components/form-fields/time-field', ['exports', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/utils/date'], function (exports, _textField, _date) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _textField.default.extend({
    control: 'one-way-time',

    serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toTimeString)(value);
      }

      return value;
    },

    deserializeValue(value, currentValue) {
      if (value != null) {
        return new Date(`${(0, _date.toDateString)(currentValue)}T${value}`);
      }

      return value;
    }
  });
});