define("ember-table/-private/utils/observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeObserver = _exports.addObserver = _exports.observer = void 0;
  var USE_ASYNC_OBSERVERS = true;

  function asyncObserver() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var fn = args.pop();
    var dependentKeys = args;
    var sync = false;
    return Ember.observer({
      dependentKeys,
      fn,
      sync
    });
  }

  function asyncAddObserver() {
    var obj, path, target, method;
    var sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && Ember.assert("Expected 3 or 4 args for addObserver, got ".concat(arguments.length), arguments.length === 3 || arguments.length === 4));

    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else if (arguments.length === 4) {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }

    return Ember.addObserver(obj, path, target, method, sync);
  }

  function asyncRemoveObserver() {
    var obj, path, target, method;
    var sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && Ember.assert("Expected 3 or 4 args for addObserver, got ".concat(arguments.length), arguments.length === 3 || arguments.length === 4));

    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }

    return Ember.removeObserver(obj, path, target, method, sync);
  }

  var observer = USE_ASYNC_OBSERVERS ? asyncObserver : Ember.observer;
  _exports.observer = observer;
  var addObserver = USE_ASYNC_OBSERVERS ? asyncAddObserver : Ember.addObserver;
  _exports.addObserver = addObserver;
  var removeObserver = Ember.removeObserver ? asyncRemoveObserver : Ember.removeObserver;
  _exports.removeObserver = removeObserver;
});