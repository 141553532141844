define('ember-form-for/components/form-fields/hidden-field', ['exports', 'ember-form-for/templates/components/form-fields/hidden-field'], function (exports, _hiddenField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const Component = Ember.Component,
        set = Ember.set;


  const HiddenFieldComponent = Component.extend({
    tagName: '',
    layout: _hiddenField.default,

    control: 'one-way-hidden',

    update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  HiddenFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = HiddenFieldComponent;
});