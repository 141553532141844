define("ember-animated/-private/ember-scheduler", ["exports", "ember-animated/-private/scheduler", "ember-animated"], function (_exports, _scheduler, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.task = task;
  _exports.timeout = timeout;
  _exports.TaskProperty = void 0;

  function task(taskFn) {
    var tp = _computed(function (propertyName) {
      var task = new Task(this, taskFn, tp, propertyName);
      task._bufferPolicy = null;
      task._observes = null;
      return task;
    });

    Object.setPrototypeOf(tp, TaskProperty.prototype);
    return tp;
  }

  function _computed(fn) {
    if (true) {
      var cp = function cp(proto, key) {
        if (cp.setup !== undefined) {
          cp.setup(proto, key);
        }

        return Ember.computed(fn)(...arguments);
      };

      Ember._setClassicDecorator(cp);

      return cp;
    } else {
      return Ember.computed(fn);
    }
  }

  var handlerCounter = 0;
  var TaskProperty;
  _exports.TaskProperty = TaskProperty;

  if (true) {
    _exports.TaskProperty = TaskProperty = class {};
  } else {
    _exports.TaskProperty = TaskProperty = class extends Ember.ComputedProperty {
      callSuperSetup() {
        if (super.setup) {
          super.setup(...arguments);
        }
      }

    };
  }

  Ember.assign(TaskProperty.prototype, {
    restartable() {
      this._bufferPolicy = cancelAllButLast;
      return this;
    },

    drop() {
      this._bufferPolicy = drop;
      return this;
    },

    observes() {
      for (var _len = arguments.length, deps = new Array(_len), _key = 0; _key < _len; _key++) {
        deps[_key] = arguments[_key];
      }

      this._observes = deps;
      return this;
    },

    setup(proto, taskName) {
      if (this.callSuperSetup) {
        this.callSuperSetup(...arguments);
      }

      if (this._observes) {
        for (var i = 0; i < this._observes.length; ++i) {
          var name = this._observes[i];
          var handlerName = "_ember_animated_handler_".concat(handlerCounter++);

          proto[handlerName] = function () {
            var task = this.get(taskName);

            for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
              args[_key2] = arguments[_key2];
            }

            Ember.run.scheduleOnce('actions', task, '_safeInvokeCallback', 'perform', args);
          };

          Ember.addObserver(proto, name, null, handlerName);
        }
      }
    }

  });
  var priv = new WeakMap();

  class Task {
    constructor(context, implementation, taskProperty, name) {
      priv.set(this, {
        context,
        implementation,
        instances: [],
        taskProperty,
        name
      });
      this.concurrency = 0;
      this.isRunning = false;
    }

    perform() {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      var self = this;
      var privSelf = priv.get(this);
      var context = privSelf.context;
      var implementation = privSelf.implementation;
      var policy = privSelf.taskProperty._bufferPolicy;

      if (context.isDestroyed) {
        throw new Error("Tried to perform task ".concat(privSelf.name, " on an already destroyed object"));
      }

      cleanupOnDestroy(context, this, 'cancelAll');
      return (0, _scheduler.spawn)(function* () {
        if (false
        /* DEBUG */
        ) {
          (0, _scheduler.logErrors)(error => {
            if (Ember.testing) {
              Ember.Test.adapter.exception(error);
            } else {
              (0, _emberAnimated.microwait)().then(() => {
                throw error;
              });
            }
          });
        }

        try {
          self._addInstance((0, _scheduler.current)());

          if (policy) {
            var maybeWait = policy(self, privSelf);

            if (maybeWait) {
              yield maybeWait;
            }
          }

          var finalValue = yield* withRunLoop(implementation.call(context, ...args));
          return finalValue;
        } finally {
          Ember.run.join(() => {
            self._removeInstance((0, _scheduler.current)());
          });
        }
      });
    }

    cancelAll() {
      priv.get(this).instances.forEach(i => (0, _scheduler.stop)(i));
    }

    _addInstance(i) {
      priv.get(this).instances.push(i);
      Ember.set(this, 'isRunning', true);
      Ember.set(this, 'concurrency', this.concurrency + 1);
    }

    _removeInstance(i) {
      var instances = priv.get(this).instances;
      instances.splice(instances.indexOf(i), 1);
      Ember.set(this, 'concurrency', this.concurrency - 1);
      Ember.set(this, 'isRunning', this.concurrency > 0);
    }

    _safeInvokeCallback(method, args) {
      var {
        context
      } = priv.get(this);

      if (!context.isDestroyed) {
        this[method].apply(this, args);
      }
    }

  } // cribbed from machty's ember-concurrency


  function cleanupOnDestroy(owner, object, cleanupMethodName) {
    if (!owner.willDestroy) {
      // we're running in non Ember object (possibly in a test mock)
      return;
    }

    if (!owner.willDestroy.__ember_processes_destroyers__) {
      var oldWillDestroy = owner.willDestroy;
      var disposers = [];

      owner.willDestroy = function () {
        for (var i = 0, l = disposers.length; i < l; i++) {
          disposers[i]();
        }

        oldWillDestroy.apply(owner, arguments);
      };

      owner.willDestroy.__ember_processes_destroyers__ = disposers;
    }

    owner.willDestroy.__ember_processes_destroyers__.push(() => {
      try {
        object[cleanupMethodName]();
      } catch (err) {
        if (err.message !== 'TaskCancelation') {
          throw err;
        }
      }
    });
  }

  function cancelAllButLast(task, privTask) {
    var instances = privTask.instances;

    for (var i = 0; i < instances.length - 1; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }

  function drop(task, privTask) {
    var instances = privTask.instances;

    for (var i = 1; i < instances.length; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }

  function* withRunLoop(generator) {
    var state;
    var nextValue;
    var fulfilled = true;

    while (true) {
      Ember.run.join(() => {
        try {
          if (fulfilled) {
            state = generator.next(nextValue);
          } else {
            state = generator.throw(nextValue);
          }
        } catch (err) {
          state = {
            threw: err
          };
        }
      });

      if (state.done) {
        return state.value;
      }

      if (state.threw) {
        throw state.threw;
      }

      try {
        nextValue = yield state.value;
        fulfilled = true;
      } catch (err) {
        nextValue = err;
        fulfilled = false;
      }
    }
  }

  function timeout(ms) {
    return new Ember.RSVP.Promise(resolve => setTimeout(resolve, ms));
  }
});