define("bp-ember-components/components/data-grid-page-object", ["exports", "ember-cli-page-object", "ember-table/test-support"], function (_exports, _emberCliPageObject, _testSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    filter: (0, _emberCliPageObject.fillable)("[data-test-fulltext-filter]"),
    table: new _testSupport.TablePage()
  };
  _exports.default = _default;
});