define("ember-safe-button/templates/components/safe-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N1j42p1V",
    "block": "{\"symbols\":[\"@onConfirm\",\"&default\",\"&attrs\"],\"statements\":[[5,\"animated-container\",[[12,\"class\",\"ember-safe-button\"],[13,3]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"animated-if\",[[24,[\"safetyOn\"]]],[[\"rules\"],[[24,[\"rules\"]]]],{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"hash\",null,[[\"safety\"],[[28,\"component\",[\"safety\"],[[\"onTrigger\",\"safetyOn\"],[[24,[\"triggerSafety\"]],[24,[\"safetyOn\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"safety\",null,[[\"onTrigger\",\"safetyOn\",\"displayedMessage\"],[[24,[\"triggerSafety\"]],[24,[\"safetyOn\"]],[23,0,[\"displayedMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"hash\",null,[[\"trigger\"],[[28,\"component\",[\"trigger\"],[[\"onConfirm\",\"safetyOn\"],[[23,1,[]],[24,[\"safetyOn\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"trigger\",null,[[\"onConfirm\",\"safetyOn\",\"displayedMessage\"],[[23,1,[]],[24,[\"safetyOn\"]],[23,0,[\"displayedMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-safe-button/templates/components/safe-button.hbs"
    }
  });

  _exports.default = _default;
});