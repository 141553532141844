define("bp-ember-components/validations/company", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)(true)],
    note: [],
    address: [(0, _validators.validateLength)({
      min: 0,
      max: 255
    })],
    website: [(0, _validators.validateFormat)({
      type: "url",
      allowBlank: true
    })],
    facebook: [(0, _validators.validateFormat)({
      type: "url",
      allowBlank: true
    })],
    facebookTitle: [(0, _validators.validateLength)({
      min: 0,
      max: 255
    })],
    instagram: [(0, _validators.validateFormat)({
      type: "url",
      allowBlank: true
    })],
    instagramTitle: [(0, _validators.validateLength)({
      min: 0,
      max: 255
    })],
    phone: [(0, _validators.validateFormat)({
      regex: /^[+]?[()/0-9. -]{9,}$/,
      allowBlank: true
    })],
    phoneLandline: [(0, _validators.validateFormat)({
      regex: /^[+]?[()/0-9. -]{9,}$/,
      allowBlank: true
    })],
    email: [(0, _validators.validateFormat)({
      type: "email",
      allowBlank: true
    })]
  };
  _exports.default = _default;
});