define("bp-ember-components/components/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @value}}
    {{format-number @value style="currency" currency="CZK"}}
  {{/if}}
  */
  {
    id: "QgKhuLtZ",
    block: "{\"symbols\":[\"@value\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"format-number\",[[23,1,[]]],[[\"style\",\"currency\"],[\"currency\",\"CZK\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/money.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});