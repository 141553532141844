define("ember-animated/box-shadow", ["exports", "ember-animated/color", "ember-animated", "ember-animated/easings/linear", "ember-animated/element-remove"], function (_exports, _color, _emberAnimated, _linear, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BoxShadowTween = _exports.BoxShadow = void 0;
  var innerPattern = /^ (\d+)px (\d+)px(?: (\d+)px)?(?: (\d+)px)?( inset)?(?:, )?/;

  class BoxShadow {
    static fromComputedStyle(string) {
      var originalString = string;
      var shadows = [];

      if (!string || string === 'none') {
        return shadows;
      }

      while (string.length > 0) {
        var color = _color.Color.fromComputedStyle(string);

        string = string.slice(color.sourceString.length);
        var m = innerPattern.exec(string);

        if (!m) {
          throw new Error("failed to parse computed shadow ".concat(originalString));
        }

        var x = parseInt(m[1]);
        var y = parseInt(m[2]);
        var blur = m[3] == null ? 0 : parseInt(m[3]);
        var spread = m[4] == null ? 0 : parseInt(m[4]);
        var inset = m[5] != null;
        shadows.push(new BoxShadow({
          color,
          x,
          y,
          blur,
          spread,
          inset
        }));
        string = string.slice(m[0].length);
      }

      return shadows;
    }

    static fromUserProvidedShadow(string) {
      var testElement = document.createElement('div');
      testElement.style.display = 'none';
      testElement.style['box-shadow'] = string;
      document.body.appendChild(testElement);
      var result = this.fromComputedStyle(getComputedStyle(testElement)['box-shadow']);
      testElement.remove();
      return result;
    }

    constructor(_ref) {
      var {
        color,
        x,
        y,
        blur,
        spread,
        inset
      } = _ref;
      this.color = color;
      this.x = x;
      this.y = y;
      this.blur = blur;
      this.spread = spread;
      this.inset = inset;
    }

    toString() {
      return "".concat(this.inset ? 'inset ' : '').concat(this.x, "px ").concat(this.y, "px ").concat(this.blur, "px ").concat(this.spread, "px ").concat(this.color.toString());
    }

  }

  _exports.BoxShadow = BoxShadow;

  function emptyShadowOfType(otherShadow) {
    return new BoxShadow({
      color: _color.Color.fromComputedStyle('rgba(0, 0, 0, 0)'),
      blur: 0,
      spread: 0,
      x: 0,
      y: 0,
      inset: otherShadow.inset
    });
  }

  class BoxShadowTween {
    constructor(fromShadows, toShadows, duration) {
      var easing = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _linear.default;
      var shadowCount = Math.max(fromShadows.length, toShadows.length);

      if (fromShadows.length < shadowCount) {
        fromShadows = fromShadows.slice();

        while (fromShadows.length < shadowCount) {
          fromShadows.push(emptyShadowOfType(toShadows[fromShadows.length]));
        }
      }

      if (toShadows.length < shadowCount) {
        toShadows = toShadows.slice();

        while (toShadows.length < shadowCount) {
          toShadows.push(emptyShadowOfType(fromShadows[toShadows.length]));
        }
      }

      this.shadowTweens = fromShadows.map((fromShadow, index) => new OneShadowTween(fromShadow, toShadows[index], duration, easing));
    }

    get currentValue() {
      return this.shadowTweens.map(tween => tween.currentValue);
    }

    get done() {
      return this.shadowTweens.every(tween => tween.done);
    }

  }

  _exports.BoxShadowTween = BoxShadowTween;

  class OneShadowTween {
    constructor(fromShadow, toShadow, duration, easing) {
      this.colorTween = new _color.ColorTween(fromShadow.color, toShadow.color, duration, easing);
      this.xTween = new _emberAnimated.Tween(fromShadow.x, toShadow.x, duration, easing);
      this.yTween = new _emberAnimated.Tween(fromShadow.y, toShadow.y, duration, easing);
      this.blurTween = new _emberAnimated.Tween(fromShadow.blur, toShadow.blur, duration, easing);
      this.spreadTween = new _emberAnimated.Tween(fromShadow.spread, toShadow.spread, duration, easing);
      this.inset = fromShadow.inset;
    }

    get currentValue() {
      return new BoxShadow({
        x: this.xTween.currentValue,
        y: this.yTween.currentValue,
        blur: this.blurTween.currentValue,
        spread: this.spreadTween.currentValue,
        inset: this.inset,
        color: this.colorTween.currentValue
      });
    }

    get done() {
      return [this.colorTween, this.xTween, this.yTween, this.blurTween, this.spreadTween].every(tween => tween.done);
    }

  }
});