define("ember-composable-helpers/helpers/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      var [callback, array] = _ref;
      Ember.set(this, 'array', array);
      Ember.set(this, 'callback', callback);
      return Ember.get(this, 'content');
    },

    callbackDidChange: Ember.observer('callback', function () {
      var callback = Ember.get(this, 'callback');

      if (Ember.isEmpty(callback)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }

      var cp = Ember.computed.filter('array', callback);
      Ember.defineProperty(this, 'content', cp);
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});