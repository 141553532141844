define("bp-ember-components/components/x-date/long", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{moment-format this.value "LLL"}}
  */
  {
    id: "mUxk0KRQ",
    block: "{\"symbols\":[],\"statements\":[[1,[28,\"moment-format\",[[23,0,[\"value\"]],\"LLL\"],null],false]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-date/long.hbs"
    }
  });

  var Long = (_dec = (0, _component.tagName)("span"), _dec(_class = class Long extends Ember.Component {}) || _class);
  _exports.default = Long;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Long);
});