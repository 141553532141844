define("ember-animated/components/animated-orphans", ["exports", "ember-animated/templates/components/animated-orphans", "ember-animated/-private/ember-scheduler", "ember-animated", "ember-animated/-private/transition-context", "ember-animated/-private/scheduler", "ember-animated/-private/sprite", "ember-animated/-private/partition", "ember-animated/element-remove"], function (_exports, _animatedOrphans, _emberScheduler, _emberAnimated, _transitionContext, _scheduler, _sprite, _partition, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A component that adopts any orphaned sprites so they can continue animating even
    after their original parent component has been destroyed. This relies on cloning
    DOM nodes, and the cloned nodes will be inserted as children of animated-orphans.
    ```hbs
    {{animated-orphans}}
    ```
    @class animated-orphans
    @public
  */
  var _default = Ember.Component.extend({
    layout: _animatedOrphans.default,
    classNames: ['animated-orphans'],
    motionService: Ember.inject.service('-ea-motion'),

    init() {
      this._super();

      this._newOrphanTransitions = [];
      this._elementToChild = new WeakMap();
      this._childToTransition = new WeakMap();
      this._inserted = false;
      this._cycleCounter = 0;
    },

    didInsertElement() {
      this._inserted = true;
      this.animateOrphans = this.animateOrphans.bind(this);
      this.reanimate = this.reanimate.bind(this);
      this.get("motionService").register(this).observeOrphans(this.animateOrphans).observeAnimations(this.reanimate);
    },

    willDestroyElement() {
      this.get("motionService").unregister(this).unobserveOrphans(this.animateOrphans).unobserveAnimations(this.reanimate);
    },

    animateOrphans(removedSprites, transition, duration, shouldAnimateRemoved) {
      this._newOrphanTransitions.push({
        removedSprites: removedSprites.map(sprite => {
          // we clone the owner objects so that our sprite garbage
          // collection is entirely detached from the original
          // animator's
          sprite.owner = sprite.owner.clone();
          sprite.owner.flagForRemoval();
          return sprite;
        }),
        transition,
        duration,
        shouldAnimateRemoved
      });

      this.reanimate();
    },

    reanimate() {
      if (!this.get('startAnimation.isRunning')) {
        var ownSprite = new _sprite.default(this.element, true, null, null);

        var activeSprites = this._findActiveSprites(ownSprite);

        this.get('animate').perform({
          ownSprite,
          activeSprites
        });
      }
    },

    beginStaticMeasurement() {// we don't have any impact on static layout
    },

    endStaticMeasurement() {},

    isAnimating: Ember.computed.alias('animate.isRunning'),
    animate: (0, _emberScheduler.task)(function* (_ref) {
      var {
        ownSprite,
        activeSprites
      } = _ref;
      yield this.get('startAnimation').perform(ownSprite);
      var {
        matchingAnimatorsFinished
      } = yield this.get('runAnimation').perform(activeSprites, ownSprite);
      yield this.get('finalizeAnimation').perform(activeSprites, matchingAnimatorsFinished);
    }).restartable(),
    startAnimation: (0, _emberScheduler.task)(function* (ownSprite) {
      yield (0, _emberAnimated.afterRender)();
      ownSprite.measureFinalBounds();
    }),
    runAnimation: (0, _emberScheduler.task)(function* (activeSprites, ownSprite) {
      var _this = this;

      // we don't need any static measurements, but we wait for this so
      // we stay on the same timing as all the other animators
      yield* this.get('motionService').staticMeasurement(() => {}); // Some of the new orphan transitions may be handing us sprites we
      // already have matches for, in which case our active sprites take
      // precedence.

      {
        (function () {
          var activeIds = Object.create(null);

          for (var sprite of activeSprites) {
            activeIds["".concat(sprite.owner.group, "/").concat(sprite.owner.id)] = true;
          }

          for (var entry of _this._newOrphanTransitions) {
            entry.removedSprites = entry.removedSprites.filter(s => !activeIds["".concat(s.owner.group, "/").concat(s.owner.id)]);
          }
        })();
      } // our sprites from prior animation runs are eligible to be
      // matched by other animators (this is how an orphan sprites that
      // are animating away can get interrupted into coming back)

      var {
        farMatches,
        matchingAnimatorsFinished
      } = yield this.get('motionService.farMatch').perform((0, _scheduler.current)(), [], [], activeSprites.concat(...this._newOrphanTransitions.map(t => t.removedSprites)));
      var cycle = this._cycleCounter++;

      var _loop = function _loop(transition, duration, sprites) {
        var [sentSprites, removedSprites] = (0, _partition.default)(sprites, sprite => {
          var other = farMatches.get(sprite);

          if (other) {
            sprite.endAtSprite(other);

            if (other.revealed && !sprite.revealed) {
              sprite.startAtSprite(other);
            }

            return true;
          }
        });
        var context = new _transitionContext.default(duration, [], [], removedSprites, sentSprites, []);
        context.onMotionStart = _this._onMotionStart.bind(_this, cycle);
        context.onMotionEnd = _this._onMotionEnd.bind(_this, cycle);
        (0, _scheduler.spawnChild)(function* () {
          // let other animators make their own partitioning decisions
          // before we start hiding the sent & received sprites
          yield (0, _emberAnimated.microwait)();
          sentSprites.forEach(s => s.hide());
          yield* context._runToCompletion(transition);
        });
      };

      for (var _ref2 of this._groupActiveSprites(activeSprites)) {
        var {
          transition,
          duration,
          sprites
        } = _ref2;

        _loop(transition, duration, sprites);
      }

      var _loop2 = function _loop2() {
        // This is a pop instead of a shift because we want to start the
        // animations in the reverse order that they were
        // scheduled. This is a consequence of Ember's
        // willDestroyElement firing from top to bottom. We want
        // descendants have a chance to start before their ancestors,
        // because each one is going to potentially trigger DOM cloning,
        // so any animated descendants need to get hidden before one of
        // their ancestors clones them.
        var entry = _this._newOrphanTransitions.pop();

        var {
          transition,
          duration,
          removedSprites,
          shouldAnimateRemoved
        } = entry;

        if (removedSprites.length === 0) {
          // This can happen due to our filtering based on activeIds
          // above: some new orphan transitions may have nothing new
          // that we aren't already animating.
          return "continue";
        }

        for (var sprite of removedSprites) {
          sprite.rehome(ownSprite);

          _this._childToTransition.set(sprite.owner, entry);
        }

        var [sentSprites, unmatchedRemovedSprites] = (0, _partition.default)(removedSprites, sprite => {
          var other = farMatches.get(sprite);

          if (other) {
            sprite.endAtSprite(other);

            if (other.revealed && !sprite.revealed) {
              sprite.startAtSprite(other);
            }

            return true;
          }
        });
        var self = _this;
        (0, _scheduler.spawnChild)(function* () {
          yield (0, _emberAnimated.microwait)();
          sentSprites.forEach(s => s.hide()); // now that we've hidden any sent sprites, we can bail out
          // early if there is no transition they want to run

          if (!transition) {
            return;
          }

          var removedSprites;

          if (shouldAnimateRemoved) {
            removedSprites = unmatchedRemovedSprites;
          } else {
            removedSprites = [];
          } // Early bail out if there's nothing left that could animate


          if (removedSprites.length === 0 && sentSprites.length === 0) {
            return;
          }

          var context = new _transitionContext.default(duration, [], [], removedSprites, sentSprites, []);
          context.onMotionStart = self._onFirstMotionStart.bind(self, activeSprites, cycle);
          context.onMotionEnd = self._onMotionEnd.bind(self, cycle);
          context.prepareSprite = self._prepareSprite.bind(self);
          yield* context._runToCompletion(transition);
        });
      };

      while (this._newOrphanTransitions.length > 0) {
        var _ret = _loop2();

        if (_ret === "continue") continue;
      }

      yield (0, _scheduler.childrenSettled)();
      return {
        matchingAnimatorsFinished
      };
    }),
    finalizeAnimation: (0, _emberScheduler.task)(function* (activeSprites, matchingAnimatorsFinished) {
      yield matchingAnimatorsFinished;

      for (var sprite of activeSprites) {
        sprite.element.remove();
      }
    }),

    _findActiveSprites(ownSprite) {
      if (!this._inserted) {
        return [];
      }

      return Array.from(this.element.children).map(element => {
        var child = this._elementToChild.get(element);

        if (child.shouldRemove) {
          // child was not animating in the previously interrupted
          // animation, so its safe to remove
          element.remove();
        } else {
          var sprite = _sprite.default.positionedStartingAt(element, ownSprite);

          sprite.owner = child; // we need to flag each existing child for removal at the
          // start of each animation. That's what reinitializes its
          // removal blockers count.

          child.flagForRemoval();
          return sprite;
        }
      }).filter(Boolean);
    },

    _groupActiveSprites(activeSprites) {
      var _this2 = this;

      var groups = [];

      var _loop3 = function _loop3(sprite) {
        var {
          transition,
          duration
        } = _this2._childToTransition.get(sprite.owner);

        var group = groups.find(g => g.transition === transition);

        if (!group) {
          group = {
            transition,
            duration,
            sprites: []
          };
          groups.push(group);
        }

        group.sprites.push(sprite);
      };

      for (var sprite of activeSprites) {
        _loop3(sprite);
      }

      return groups;
    },

    _prepareSprite(sprite) {
      sprite.hide();
      var newElement = sprite.element.cloneNode(true);
      (0, _emberAnimated.continueMotions)(sprite.element, newElement);
      sprite.element = newElement;
      return sprite;
    },

    _onFirstMotionStart(activeSprites, cycle, sprite) {
      if (activeSprites.indexOf(sprite) === -1) {
        // in most animators, sprites are still living in their normal place in
        // the DOM, and so they will necessarily start out with their appearance
        // matching initialComputedStyles. But here we're dealing with an orphan
        // who may have lost inherited styles. So we manually re-apply the
        // initialComputedStyles that were snapshotted before it was moved. See
        // COPIED_CSS_PROPERTIES to see exactly which property we copy (we don't
        // do all of them, that sounds expensive).
        //
        // Also, unfortunately getComputedStyles has legacy behavior for
        // line-height that gives us the "used value" not the "computed value".
        // The used value doesn't inherit correctly, so we can't set it here, so
        // we're pulling that one out.
        var s = Object.assign({}, sprite.initialComputedStyle);
        delete s['line-height'];
        sprite.applyStyles(s);
        this.element.appendChild(sprite.element);
        sprite.lock();
        sprite.reveal();
        activeSprites.push(sprite);

        this._elementToChild.set(sprite.element, sprite.owner);
      }

      sprite.owner.block(cycle);
    },

    _onMotionStart(cycle, sprite) {
      sprite.reveal();
      sprite.owner.block(cycle);
    },

    _onMotionEnd(cycle, sprite) {
      sprite.owner.unblock(cycle);
    }

  });

  _exports.default = _default;
});