define("bp-ember-components/utils/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sum;

  function sum(_ref) {
    var {
      price,
      count,
      discount = 0
    } = _ref;
    return count * price * (1 - discount / 100);
  }
});