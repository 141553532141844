define("ember-concurrency/-task-state-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    alias
  } = Ember.computed; // this is a mixin of properties/methods shared between Tasks and TaskGroups

  var _default = Ember.Mixin.create({
    isRunning: Ember.computed.gt('numRunning', 0),
    isQueued: Ember.computed.gt('numQueued', 0),
    isIdle: Ember.computed('isRunning', 'isQueued', function () {
      return !this.get('isRunning') && !this.get('isQueued');
    }),
    state: Ember.computed('isRunning', 'isQueued', function () {
      if (this.get('isRunning')) {
        return 'running';
      } else if (this.get('isQueued')) {
        return 'queued';
      } else {
        return 'idle';
      }
    }),
    _propertyName: null,
    _origin: null,
    name: alias('_propertyName'),
    concurrency: alias('numRunning'),
    last: alias('_scheduler.lastStarted'),
    lastRunning: alias('_scheduler.lastRunning'),
    lastPerformed: alias('_scheduler.lastPerformed'),
    lastSuccessful: alias('_scheduler.lastSuccessful'),
    lastComplete: alias('_scheduler.lastComplete'),
    lastErrored: alias('_scheduler.lastErrored'),
    lastCanceled: alias('_scheduler.lastCanceled'),
    lastIncomplete: alias('_scheduler.lastIncomplete'),
    performCount: alias('_scheduler.performCount'),
    numRunning: 0,
    numQueued: 0,
    _seenIndex: 0,

    cancelAll(options) {
      var {
        reason,
        resetState
      } = options || {};
      reason = reason || ".cancelAll() was explicitly called on the Task";

      this._scheduler.cancelAll(reason);

      if (resetState) {
        this._resetState();
      }
    },

    group: Ember.computed(function () {
      return this._taskGroupPath && this.context.get(this._taskGroupPath);
    }),
    _scheduler: null,

    _resetState() {
      this.setProperties({
        'last': null,
        'lastRunning': null,
        'lastStarted': null,
        'lastPerformed': null,
        'lastSuccessful': null,
        'lastComplete': null,
        'lastErrored': null,
        'lastCanceled': null,
        'lastIncomplete': null,
        'performCount': 0
      });
    }

  });

  _exports.default = _default;
});