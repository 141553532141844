define("ember-data/store", ["exports", "@ember-data/store", "@ember-data/record-data/-private", "@ember-data/store/-private"], function (_exports, _store, _private, _private2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DefaultStore extends _store.default {
    createRecordDataFor(modelName, id, clientId, storeWrapper) {
      if (true
      /* IDENTIFIERS */
      ) {
        var identifier = (0, _private2.identifierCacheFor)(this).getOrCreateRecordIdentifier({
          type: modelName,
          id,
          lid: clientId
        });
        return new _private.RecordData(identifier, storeWrapper);
      } else {
        return new _private.RecordData(modelName, id, clientId, storeWrapper);
      }
    }

  }

  _exports.default = DefaultStore;
});