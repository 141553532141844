define("bp-ember-components/components/x-date/time", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{moment-format this.value "HH:mm"}}
  */
  {
    id: "jUFNSPg1",
    block: "{\"symbols\":[],\"statements\":[[1,[28,\"moment-format\",[[23,0,[\"value\"]],\"HH:mm\"],null],false]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-date/time.hbs"
    }
  });

  var Time = (_dec = (0, _component.tagName)("span"), _dec(_class = class Time extends Ember.Component {}) || _class);
  _exports.default = Time;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Time);
});