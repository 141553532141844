define("ember-validated-form/templates/components/validated-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6J7Rf/Hp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[22,\"label\"],false],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\" \"],[1,[22,\"requiredLabel\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-validated-form/templates/components/validated-label.hbs"
    }
  });

  _exports.default = _default;
});