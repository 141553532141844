define('ember-form-for/utils/strings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String;
  const capitalize = _Ember$String.capitalize,
        decamelize = _Ember$String.decamelize;


  const WORD_SEPERATORS = new RegExp('[-_\\. ]', 'g');

  const humanize = exports.humanize = string => {
    return capitalize(decamelize(string.toString()).replace(WORD_SEPERATORS, ' '));
  };

  const titlecase = exports.titlecase = string => decamelize(string).split(WORD_SEPERATORS).map(w => capitalize(w)).join(' ');
});