define("ember-animated/-private/concurrency-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rAF = rAF;
  _exports.microwait = microwait;
  _exports.wait = wait;
  _exports.afterRender = afterRender;
  _exports.allSettled = allSettled;
  _exports.clock = _exports.currentFrameClock = _exports.Promise = void 0;
  var Promise;
  _exports.Promise = Promise;

  if (window.Promise) {
    _exports.Promise = Promise = window.Promise;
  } else {
    (false && Ember.warn("Unable to achieve proper rAF timing on this browser, microtask-based Promise implementation needed.", false, {
      id: "ember-animated-missing-microtask"
    }));
    _exports.Promise = Promise = Ember.RSVP.Promise;
  } // This is a cancelable way to requestAnimationFrame that's designed
  // to resolve via the microtask queue (like real spec-compliant
  // Promises are supposed to). This lets us use rAF within
  // ember-concurrency correctly. RSVP promises within Ember do not
  // resolve on the microtask queue, because Ember schedules them inside
  // backburner.


  function rAF() {
    if (!nextFrame) {
      nextFrame = requestAnimationFrame(rAFDidFire);
    }

    var promise = new Promise(resolve => {
      nextFrameWaiters.push(resolve);
    });
    promise.__ec_cancel__ = removeWaiter.bind(promise);
    return promise;
  }

  function rAFDidFire(clock) {
    nextFrame = null;
    _exports.currentFrameClock = currentFrameClock = clock;
    var waiters = nextFrameWaiters;
    nextFrameWaiters = [];

    for (var i = 0; i < waiters.length; i++) {
      waiters[i]();
    }
  }

  function removeWaiter() {
    var index = nextFrameWaiters.indexOf(this);

    if (index > -1) {
      nextFrameWaiters.splice(index, 1);
    }
  }

  var nextFrame;
  var nextFrameWaiters = []; // rAF guarantees that callbacks within the same frame will see the
  // same clock. We stash it here so that arbitrary code can easily ask
  // "did I already do that this frame?" without needing to thread the
  // clock values around.

  var currentFrameClock = null;
  _exports.currentFrameClock = currentFrameClock;

  function microwait() {
    return new Promise(resolve => resolve());
  }

  function wait() {
    var ms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    if (clock.now === originalClock) {
      var ticket;
      var promise = new Ember.RSVP.Promise(resolve => {
        ticket = setTimeout(resolve, ms);
      });

      promise.__ec_cancel__ = () => {
        clearTimeout(ticket);
      };

      return promise;
    } else {
      var canceled = false;
      var started = clock.now();

      var _promise = new Ember.RSVP.Promise(resolve => {
        function checkIt() {
          if (!canceled) {
            if (clock.now() - started > ms) {
              resolve();
            }

            rAF().then(checkIt);
          }
        }

        checkIt();
      });

      _promise.__ec_cancel__ = () => {
        canceled = true;
      };

      return _promise;
    }
  }

  function afterRender() {
    var ticket;
    var promise = new Promise(resolve => {
      ticket = Ember.run.schedule('afterRender', resolve);
    });

    promise.__ec_cancel__ = () => {
      Ember.run.cancel(ticket);
    };

    return promise;
  } // This provides a unified place to hook into time control for testing.


  var clock = {
    now() {
      return new Date().getTime();
    }

  };
  _exports.clock = clock;
  var originalClock = clock.now;

  function allSettled(promises) {
    return Promise.all(promises.map(p => {
      if (p) {
        return p.catch(() => null);
      }
    }));
  }
});