define("ember-table/-private/sticky/legacy-sticky-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setupLegacyStickyPolyfill = setupLegacyStickyPolyfill;
  _exports.teardownLegacyStickyPolyfill = teardownLegacyStickyPolyfill;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global ResizeSensor */

  /**
    These functions polyfill our usage of position: sticky; in IE11. They are not a general
    position: sticky; polyfill, and should not be used as such.
  */
  var LEGACY_POLYFILL_MAP = new WeakMap();

  class LegacyStickyPolyfill {
    constructor(element) {
      _defineProperty(this, "positionStickyElements", () => {
        var thead = this.header;
        var tfoot = this.footer;
        var leftColumn = this.element.querySelectorAll('tr > *:first-child');
        var scrollLeft = this.element.scrollLeft;
        var scrollTop = this.element.scrollTop;

        if (thead) {
          thead.style.position = 'absolute';
          thead.style.transform = "translateY(".concat(scrollTop, "px)");
          this.fakeHeaderRow.style.height = "".concat(thead.offsetHeight, "px");
        }

        if (tfoot) {
          tfoot.style.position = 'absolute';
          tfoot.style.transform = "translateY(".concat(scrollTop, "px)");
          this.fakeFooterRow.style.height = "".concat(tfoot.offsetHeight, "px");
        }

        for (var cell of leftColumn) {
          cell.style.transform = "translateX(".concat(scrollLeft, "px)");
        }
      });

      this.element = element;
      this.table = element.querySelector('table');
      this.header = element.querySelector('thead');
      this.footer = element.querySelector('tfoot');
      element.addEventListener('scroll', this.positionStickyElements);
      this.mainResizeSensor = new ResizeSensor(this.element, this.positionStickyElements);

      if (this.header) {
        this.fakeHeader = document.createElement('thead');
        this.fakeHeaderRow = document.createElement('tr');
        this.fakeHeader.insertBefore(this.fakeHeaderRow, null);
        this.headerResizeSensor = new ResizeSensor(this.header, this.positionStickyElements);
        this.table.insertBefore(this.fakeHeader, this.table.firstChild);
      }

      if (this.footer) {
        this.fakeFooter = document.createElement('tfoot');
        this.fakeFooterRow = document.createElement('tr');
        this.fakeFooter.insertBefore(this.fakeFooterRow, null);
        this.footerResizeSensor = new ResizeSensor(this.footer, this.positionStickyElements);
        this.table.insertBefore(this.fakeFooter, null);
      }

      this.positionStickyElements();
    }

    destroy() {
      this.element.removeEventListener('scroll', this.positionStickyElements);
      this.mainResizeSensor.detach(this.element);

      if (this.header) {
        this.headerResizeSensor.detach(this.header);
        this.fakeHeader.parentNode.removeChild(this.fakeHeader);
      }

      if (this.footer) {
        this.footerResizeSensor.detach(this.footer);
        this.fakeFooter.parentNode.removeChild(this.fakeFooter);
      }
    }

  }

  function setupLegacyStickyPolyfill(element) {
    LEGACY_POLYFILL_MAP.set(element, new LegacyStickyPolyfill(element));
  }

  function teardownLegacyStickyPolyfill(element) {
    LEGACY_POLYFILL_MAP.get(element).destroy();
    LEGACY_POLYFILL_MAP.delete(element);
  }
});