define("ember-validated-form/components/validated-form", ["exports", "ember-validated-form/templates/components/validated-form"], function (_exports, _validatedForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROP_ON_SUBMIT = "on-submit";
  var PROP_ON_INVALID_SUBMIT = "on-invalid-submit";

  var _default = Ember.Component.extend({
    tagName: "form",
    intl: Ember.inject.service(),
    classNameBindings: ["_cssClass", "submitted"],
    attributeBindings: ["autocomplete"],
    loading: false,
    submitted: false,
    layout: _validatedForm.default,
    validateBeforeSubmit: true,

    init() {
      this._super(...arguments);

      if (this.get("model") && this.get("model").validate) {
        this.get("model").validate();
      }
    },

    _cssClass: Ember.computed("config", function () {
      return this.get("config.css.form");
    }),
    _submitLabel: Ember.computed("config", "submit-label", function () {
      return this._getLabel("submit") || "Save";
    }),

    _getLabel(type) {
      var label = this._config(type);

      return this.intl ? this.intl.t(label) : label;
    },

    _config(type) {
      return this.get("config.label.".concat(type));
    },

    submitClass: Ember.computed("config", function () {
      return this.get("config.css.submit") || this.get("config.css.button");
    }),

    submit() {
      this.set("submitted", true);
      var model = this.get("model");

      if (!model || !model.validate) {
        this.runCallback(PROP_ON_SUBMIT);
        return false;
      }

      model.validate().then(() => {
        if (!this.element) {
          // We were removed from the DOM while validating
          return;
        }

        if (model.get("isInvalid")) {
          this.runCallback(PROP_ON_INVALID_SUBMIT);
        } else {
          this.runCallback(PROP_ON_SUBMIT);
        }
      });
      return false;
    },

    runCallback(callbackProp) {
      var callback = this.get(callbackProp);

      if (typeof callback !== "function") {
        return;
      }

      var model = this.get("model");
      this.set("loading", true);
      Ember.RSVP.resolve(callback(model)).finally(() => {
        if (!this.element) {
          // We were removed from the DOM while running on-submit()
          return;
        }

        this.set("loading", false);
      });
    }

  });

  _exports.default = _default;
});