define("ember-validated-form/components/validated-button", ["exports", "ember-validated-form/templates/components/validated-button"], function (_exports, _validatedButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _validatedButton.default,
    type: "button",
    action: null,
    tagName: "button",
    classNameBindings: ["loadingClass"],
    attributeBindings: ["disabled", "type", "action:onclick"],
    loadingClass: Ember.computed("loading", function () {
      return this.get("loading") ? this.get("config.css.loading") : null;
    })
  });

  _exports.default = _default;
});