define("bp-ember-components/components/x-date/medium", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{moment-format this.value "DD.MM.YYYY"}}
  */
  {
    id: "xW0LiqXg",
    block: "{\"symbols\":[],\"statements\":[[1,[28,\"moment-format\",[[23,0,[\"value\"]],\"DD.MM.YYYY\"],null],false]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-date/medium.hbs"
    }
  });

  var Medium = (_dec = (0, _component.tagName)("span"), _dec(_class = class Medium extends Ember.Component {}) || _class);
  _exports.default = Medium;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Medium);
});