define("liquid-fire/action", ["exports", "liquid-fire/promise"], function (_exports, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Action {
    constructor(nameOrHandler) {
      var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (typeof nameOrHandler === 'function') {
        this.handler = nameOrHandler;
      } else {
        this.name = nameOrHandler;
      }

      this.reversed = opts.reversed;
      this.args = args;
    }

    validateHandler(transitionMap) {
      if (!this.handler) {
        this.handler = transitionMap.lookup(this.name);
      }
    }

    run(context) {
      return new _promise.default((resolve, reject) => {
        _promise.default.resolve(this.handler.apply(context, this.args)).then(resolve, reject);
      });
    }

  }

  _exports.default = Action;
});