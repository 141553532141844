define("ember-page-title/services/page-title-list", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @class page-title-list
    @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    init() {
      this._super();

      Ember.set(this, 'tokens', Ember.A());
      Ember.set(this, 'length', 0);

      this._removeExistingTitleTag();
    },

    /**
      The default separator to use between tokens.
       @property defaultSeparator
      @default ' | '
     */
    defaultSeparator: ' | ',

    /**
      The default prepend value to use.
       @property defaultPrepend
      @default true
     */
    defaultPrepend: true,

    /**
      The default replace value to use.
       @property defaultReplace
      @default null
     */
    defaultReplace: null,
    tokens: null,

    applyTokenDefaults(token) {
      var defaultSeparator = Ember.get(this, "defaultSeparator");
      var defaultPrepend = Ember.get(this, "defaultPrepend");
      var defaultReplace = Ember.get(this, "defaultReplace");

      if (token.separator == null) {
        token.separator = defaultSeparator;
      }

      if (token.prepend == null && defaultPrepend != null) {
        token.prepend = defaultPrepend;
      }

      if (token.replace == null && defaultReplace != null) {
        token.replace = defaultReplace;
      }
    },

    inheritFromPrevious(token) {
      var previous = token.previous;

      if (previous) {
        if (token.separator == null) {
          token.separator = previous.separator;
        }

        if (token.prepend == null) {
          token.prepend = previous.prepend;
        }
      }
    },

    push(token) {
      var tokenForId = this.tokens.findBy('id', token.id);

      if (tokenForId) {
        var index = this.tokens.indexOf(tokenForId);

        var _tokens = (0, _emberCopy.copy)(this.tokens);

        var _previous = tokenForId.previous;
        token.previous = _previous;
        token.next = tokenForId.next;
        this.inheritFromPrevious(token);
        this.applyTokenDefaults(token);

        _tokens.splice(index, 1, token);

        Ember.set(this, 'tokens', Ember.A(_tokens));
        return;
      }

      var previous = this.tokens.slice(-1)[0];

      if (previous) {
        token.previous = previous;
        previous.next = token;
        this.inheritFromPrevious(token);
      }

      this.applyTokenDefaults(token);
      var tokens = (0, _emberCopy.copy)(this.tokens);
      tokens.push(token);
      Ember.set(this, 'tokens', Ember.A(tokens));
      Ember.set(this, 'length', Ember.get(this, 'length') + 1);
    },

    remove(id) {
      var token = this.tokens.findBy('id', id);
      var {
        next,
        previous
      } = token;

      if (next) {
        next.previous = previous;
      }

      if (previous) {
        previous.next = next;
      }

      token.previous = token.next = null;
      var tokens = Ember.A((0, _emberCopy.copy)(this.tokens));
      tokens.removeObject(token);
      Ember.set(this, 'tokens', Ember.A(tokens));
      Ember.set(this, 'length', Ember.get(this, 'length') - 1);
    },

    visibleTokens: Ember.computed('tokens', {
      get() {
        var tokens = Ember.get(this, 'tokens');
        var i = tokens ? tokens.length : 0;
        var visible = [];

        while (i--) {
          var token = tokens[i];

          if (token.replace) {
            visible.unshift(token);
            break;
          } else {
            visible.unshift(token);
          }
        }

        return visible;
      }

    }),
    sortedTokens: Ember.computed('visibleTokens', {
      get() {
        var visible = Ember.get(this, 'visibleTokens');
        var appending = true;
        var group = [];
        var groups = Ember.A([group]);
        var frontGroups = [];
        visible.forEach(token => {
          if (token.front) {
            frontGroups.unshift(token);
          } else if (token.prepend) {
            if (appending) {
              appending = false;
              group = [];
              groups.push(group);
            }

            var lastToken = group[0];

            if (lastToken) {
              token = (0, _emberCopy.copy)(token);
              token.separator = lastToken.separator;
            }

            group.unshift(token);
          } else {
            if (!appending) {
              appending = true;
              group = [];
              groups.push(group);
            }

            group.push(token);
          }
        });
        return frontGroups.concat(groups.reduce((E, group) => E.concat(group), []));
      }

    }),

    toString() {
      var tokens = Ember.get(this, 'sortedTokens');
      var title = [];

      for (var i = 0, len = tokens.length; i < len; i++) {
        var token = tokens[i];

        if (token.title) {
          title.push(token.title);

          if (i + 1 < len) {
            title.push(token.separator);
          }
        }
      }

      return title.join('');
    },

    /**
     * Remove any existing title tags from the head.
     * @private
     */
    _removeExistingTitleTag() {
      if (this._hasFastboot()) {
        return;
      }

      var titles = document.getElementsByTagName('title');

      for (var i = 0; i < titles.length; i++) {
        var title = titles[i];
        title.parentNode.removeChild(title);
      }
    },

    _hasFastboot() {
      return !!Ember.getOwner(this).lookup('service:fastboot');
    }

  });

  _exports.default = _default;
});