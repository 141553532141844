define('ember-form-for/components/form-fields/radio-field', ['exports', 'ember-form-for/templates/components/form-fields/radio-field', 'ember-form-for/utils/strings'], function (exports, _radioField, _strings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const Component = Ember.Component,
        dasherize = Ember.String.dasherize,
        computed = Ember.computed,
        or = Ember.computed.or,
        get = Ember.get,
        service = Ember.inject.service,
        isPresent = Ember.isPresent,
        set = Ember.set;


  const RadioFieldComponent = Component.extend({
    tagName: '',
    layout: _radioField.default,

    control: 'one-way-radio',

    config: service('ember-form-for/config'),

    modelName: or('object.modelName', 'object.constructor.modelName'),

    update(object, propertyName, value) {
      set(object, propertyName, value);
    },

    labelText: computed('value', 'label', 'i18n.locale', function () {
      let i18n = get(this, 'i18n');
      let label = get(this, 'label');

      if (isPresent(label)) {
        return label;
      } else if (isPresent(i18n)) {
        return i18n.t(get(this, 'labelI18nKey'));
      } else {
        return get(this, 'label') || (0, _strings.humanize)(get(this, 'value'));
      }
    }),

    labelI18nKey: computed('config.i18nKeyPrefix', 'modelName', 'propertyName', 'value', function () {
      let value = get(this, 'value');

      if (isPresent(value)) {
        value = dasherize(value.toString());
      }

      return [get(this, 'config.i18nKeyPrefix'), dasherize(get(this, 'modelName') || ''), dasherize(get(this, 'propertyName') || ''), value].filter(x => !!x).join('.');
    })
  });

  RadioFieldComponent.reopenClass({
    positionalParams: ['propertyName', 'value']
  });

  exports.default = RadioFieldComponent;
});