define("bp-ember-components/components/x-reservation/will-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <i class="icons">
    {{#unless this.value}}
      <i class="large red dont icon"></i>
    {{/unless}}
    <i class="bullhorn icon"></i>
  </i>
  */
  {
    id: "gcTEstsH",
    block: "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"icons\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"large red dont icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"i\",true],[10,\"class\",\"bullhorn icon\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-reservation/will-notify.hbs"
    }
  });

  class WillNotify extends Ember.Component {}

  _exports.default = WillNotify;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, WillNotify);
});