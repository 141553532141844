define('ember-form-for/helpers/form-for/merge-custom-form-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formForMergeCustomFormField = formForMergeCustomFormField;
  const helper = Ember.Helper.helper;
  function formForMergeCustomFormField([formFieldsHash], { name, component }) {
    formFieldsHash[name] = component;
  }

  exports.default = helper(formForMergeCustomFormField);
});