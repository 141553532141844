define("bp-ember-components/components/data-grid/checkbox", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui toggle checkbox">
    <input type="checkbox" disabled={{true}} checked={{this.value}}>
    <label></label>
  </div>
  */
  {
    id: "s0tYb4il",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui toggle checkbox\"],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"disabled\",true],[11,\"checked\",[23,0,[\"value\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/data-grid/checkbox.hbs"
    }
  });

  var Checkbox = (_dec = (0, _component.tagName)(""), _dec(_class = class Checkbox extends Ember.Component {}) || _class);
  _exports.default = Checkbox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Checkbox);
});