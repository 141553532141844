define("ember-concurrency/-buffer-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dropButKeepLatestPolicy = _exports.cancelOngoingTasksPolicy = _exports.dropQueuedTasksPolicy = _exports.enqueueTasksPolicy = void 0;

  var saturateActiveQueue = scheduler => {
    while (scheduler.activeTaskInstances.length < scheduler.maxConcurrency) {
      var taskInstance = scheduler.queuedTaskInstances.shift();

      if (!taskInstance) {
        break;
      }

      scheduler.activeTaskInstances.push(taskInstance);
    }
  };

  function numPerformSlots(scheduler) {
    return scheduler.maxConcurrency - scheduler.queuedTaskInstances.length - scheduler.activeTaskInstances.length;
  }

  var enqueueTasksPolicy = {
    requiresUnboundedConcurrency: true,

    schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] [d,e,f]
      saturateActiveQueue(scheduler);
    },

    getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'enqueue';
    }

  };
  _exports.enqueueTasksPolicy = enqueueTasksPolicy;
  var dropQueuedTasksPolicy = {
    cancelReason: "it belongs to a 'drop' Task that was already running",

    schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length);
    },

    getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'drop';
    }

  };
  _exports.dropQueuedTasksPolicy = dropQueuedTasksPolicy;
  var cancelOngoingTasksPolicy = {
    cancelReason: "it belongs to a 'restartable' Task that was .perform()ed again",

    schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      var activeTaskInstances = scheduler.activeTaskInstances;
      var queuedTaskInstances = scheduler.queuedTaskInstances;
      activeTaskInstances.push(...queuedTaskInstances);
      queuedTaskInstances.length = 0;
      var numToShift = Math.max(0, activeTaskInstances.length - scheduler.maxConcurrency);
      scheduler.spliceTaskInstances(this.cancelReason, activeTaskInstances, 0, numToShift);
    },

    getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'cancel_previous';
    }

  };
  _exports.cancelOngoingTasksPolicy = cancelOngoingTasksPolicy;
  var dropButKeepLatestPolicy = {
    cancelReason: "it belongs to a 'keepLatest' Task that was already running",

    schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length - 1);
    }

  };
  _exports.dropButKeepLatestPolicy = dropButKeepLatestPolicy;
});