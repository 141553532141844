define("ember-changeset/utils/computed/transform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;
  var {
    keys
  } = Object;
  /**
   * Transform an Object's values with a `transform` function.
   */

  function transform(dependentKey, transform) {
    return Ember.computed(dependentKey, function () {
      var obj = Ember.get(this, dependentKey);
      return keys(obj).reduce((newObj, key) => {
        newObj[key] = transform(obj[key]);
        return newObj;
      }, {});
    });
  }
});