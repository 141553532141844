define("ember-power-select/helpers/ember-power-select-is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberPowerSelectIsSelected = emberPowerSelectIsSelected;
  _exports.default = void 0;

  // TODO: Make it private or scoped to the component
  function emberPowerSelectIsSelected(_ref
  /* , hash*/
  ) {
    var [option, selected] = _ref;

    if (selected === undefined || selected === null) {
      return false;
    }

    if (Ember.isArray(selected)) {
      for (var i = 0; i < selected.length; i++) {
        if (Ember.isEqual(selected[i], option)) {
          return true;
        }
      }

      return false;
    } else {
      return Ember.isEqual(option, selected);
    }
  }

  var _default = Ember.Helper.helper(emberPowerSelectIsSelected);

  _exports.default = _default;
});