define('ember-form-for/helpers/form-for/humanize', ['exports', 'ember-form-for/utils/strings'], function (exports, _strings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formForHumanize = formForHumanize;
  const helper = Ember.Helper.helper;
  function formForHumanize([string]) {
    return (0, _strings.humanize)(string);
  }

  exports.default = helper(formForHumanize);
});