define("ember-safe-button/components/safety", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Safety is a component that covers the trigger.
  
    ```
    <SafeButton
      @onConfirm={{action safeButtonClicked}} as |button|
    >
      <button.safety class="bg-grey-light" aria-label="Delete item">
        This is safety
      </button.safety>
    </SafeButton>
    ```
  
    @class SafetyComponent
   */
  class SafetyComponent extends _component.default {}

  _exports.default = SafetyComponent;
});