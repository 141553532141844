define('ember-one-way-select/helpers/one-way-select/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  function contains([haystack, needle, valuePath, targetPath]) {
    if (Ember.isArray(haystack)) {
      haystack = Ember.A(haystack);

      if (valuePath) {
        haystack = targetPath ? haystack : haystack.mapBy(valuePath);
        return Ember.A(haystack).includes(Ember.get(needle, valuePath));
      } else {
        return haystack.includes(needle);
      }
    } else {
      if (valuePath && Ember.isPresent(haystack) && Ember.isPresent(needle)) {
        haystack = targetPath ? haystack : Ember.get(haystack, valuePath);
        return haystack === Ember.get(needle, valuePath);
      } else {
        return haystack === needle;
      }
    }
  }

  exports.default = Ember.Helper.helper(contains);
});