define('ember-cli-flash/components/flash-message', ['exports', 'ember-cli-flash/templates/components/flash-message'], function (exports, _flashMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const and = Ember.computed.and,
        bool = Ember.computed.bool,
        readOnly = Ember.computed.readOnly,
        not = Ember.computed.not;
  const next = Ember.run.next,
        cancel = Ember.run.cancel;
  exports.default = Ember.Component.extend({
    layout: _flashMessage.default,
    active: false,
    messageStyle: 'bootstrap',
    classNames: ['flash-message'],
    classNameBindings: ['alertType', 'active', 'exiting'],
    attributeBindings: ['aria-label', 'aria-describedby', 'role'],

    showProgress: readOnly('flash.showProgress'),
    notExiting: not('exiting'),
    showProgressBar: and('showProgress', 'notExiting'),
    exiting: readOnly('flash.exiting'),
    hasBlock: bool('template').readOnly(),

    alertType: Ember.computed('flash.type', {
      get() {
        const flashType = Ember.getWithDefault(this, 'flash.type', '');
        const messageStyle = Ember.getWithDefault(this, 'messageStyle', '');
        let prefix = 'alert alert-';

        if (messageStyle === 'foundation') {
          prefix = 'alert-box ';
        }

        return `${prefix}${flashType}`;
      }
    }),

    flashType: Ember.computed('flash.type', {
      get() {
        const flashType = Ember.getWithDefault(this, 'flash.type', '');

        return Ember.String.classify(flashType);
      }
    }),

    didInsertElement() {
      this._super(...arguments);
      const pendingSet = next(this, () => {
        Ember.set(this, 'active', true);
      });
      Ember.set(this, 'pendingSet', pendingSet);
    },

    progressDuration: Ember.computed('flash.showProgress', {
      get() {
        if (!Ember.get(this, 'flash.showProgress')) {
          return false;
        }

        const duration = Ember.getWithDefault(this, 'flash.timeout', 0);

        return Ember.String.htmlSafe(`transition-duration: ${duration}ms`);
      }
    }),

    click() {
      const destroyOnClick = Ember.getWithDefault(this, 'flash.destroyOnClick', true);

      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    },

    mouseEnter() {
      const flash = Ember.get(this, 'flash');
      if (Ember.isPresent(flash)) {
        flash.preventExit();
      }
    },

    mouseLeave() {
      const flash = Ember.get(this, 'flash');
      if (Ember.isPresent(flash) && !Ember.get(flash, 'exiting')) {
        flash.allowExit();
      }
    },

    willDestroy() {
      this._super(...arguments);
      this._destroyFlashMessage();
      cancel(Ember.get(this, 'pendingSet'));
    },

    // private
    _destroyFlashMessage() {
      const flash = Ember.getWithDefault(this, 'flash', false);

      if (flash) {
        flash.destroyMessage();
      }
    },

    actions: {
      close() {
        this._destroyFlashMessage();
      }
    }
  });
});