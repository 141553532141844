define("ember-intl-changeset-validations/validations/messages", ["exports", "ember-changeset-validations/utils/messages", "ember-intl-changeset-validations/utils/application"], function (_exports, _messages, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    keys
  } = Object;

  var intl = _application.default.instance.lookup('service:intl');

  var messages = {};
  keys(_messages.default).forEach(key => {
    var lookupKey = "validations.".concat(key);

    if (intl.exists(lookupKey)) {
      messages[key] = intl.lookup(lookupKey);
    } else {
      messages[key] = _messages.default[key];
    }
  });
  var _default = messages;
  _exports.default = _default;
});