define("ember-animated/color", ["exports", "ember-animated", "ember-animated/easings/linear", "ember-animated/element-remove"], function (_exports, _emberAnimated, _linear, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ColorTween = _exports.Color = void 0;

  // We use Element.remove in our color parser below.
  class Color {
    static fromComputedStyle(colorString) {
      var channels = parseComputedColor(colorString);
      return new Color(channels, channels.m[0]);
    }

    static fromUserProvidedColor(colorString) {
      return new Color(parseUserProvidedColor(colorString), colorString);
    }

    toString() {
      return "rgba(".concat(this.r, ", ").concat(this.g, ", ").concat(this.b, ", ").concat(this.a, ")");
    }

    constructor(_ref, sourceString) {
      var {
        r,
        g,
        b,
        a
      } = _ref;
      this.r = r;
      this.g = g;
      this.b = b;
      this.a = a;
      this.sourceString = sourceString;
    }

  }

  _exports.Color = Color;

  class ColorTween {
    constructor(initialColor, finalColor, duration) {
      var easing = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _linear.default;
      this.rTween = new _emberAnimated.Tween(initialColor.r * initialColor.a, finalColor.r * finalColor.a, duration, easing);
      this.gTween = new _emberAnimated.Tween(initialColor.g * initialColor.a, finalColor.g * finalColor.a, duration, easing);
      this.bTween = new _emberAnimated.Tween(initialColor.b * initialColor.a, finalColor.b * finalColor.a, duration, easing);
      this.aTween = new _emberAnimated.Tween(initialColor.a, finalColor.a, duration, easing);
    }

    get currentValue() {
      var nonZeroAlpha = this.aTween.currentValue || 1;
      return new Color({
        r: Math.floor(this.rTween.currentValue / nonZeroAlpha),
        g: Math.floor(this.gTween.currentValue / nonZeroAlpha),
        b: Math.floor(this.bTween.currentValue / nonZeroAlpha),
        a: this.aTween.currentValue
      });
    }

    get done() {
      return [this.rTween, this.gTween, this.bTween, this.aTween].every(tween => tween.done);
    }

  }

  _exports.ColorTween = ColorTween;

  function parseComputedColor(c) {
    var m = /^rgb\((\d+), (\d+), (\d+)\)/.exec(c);

    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: 1,
        m
      };
    }

    m = /^rgba\((\d+), (\d+), (\d+), (\d+(?:\.\d+)?)\)/.exec(c);

    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: parseFloat(m[4]),
        m
      };
    }

    throw new Error("unable to parse color ".concat(c));
  }

  function parseUserProvidedColor(c) {
    var testElement = document.createElement('div');
    testElement.style.display = 'none';
    testElement.style.color = c;
    document.body.appendChild(testElement);
    var result = parseComputedColor(getComputedStyle(testElement).color);
    testElement.remove();
    return result;
  }
});