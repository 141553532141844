define("ember-animated/motions/follow", ["exports", "ember-animated/motions/move", "ember-animated"], function (_exports, _move, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = follow;
  _exports.Follow = void 0;

  function follow(sprite, opts) {
    return new Follow(sprite, opts).run();
  } // Because we inherit from Move, if we are interrupted by a Move the
  // new Move will still preserve our momentum.


  class Follow extends _move.Move {
    constructor(sprite, opts) {
      super(sprite, opts);

      if (!(this.opts.source instanceof _move.Move)) {
        throw new Error("Follow requires a `source` Move to follow");
      }
    }

    *animate() {
      var source = this.opts.source;
      var sprite = this.sprite;
      var transformOffsetX = sprite.transform.tx - source.sprite.transform.tx;
      var transformOffsetY = sprite.transform.ty - source.sprite.transform.ty;
      this.xTween = new _emberAnimated.Tween(transformOffsetX, transformOffsetX, 0).plus(source.xTween);
      this.yTween = new _emberAnimated.Tween(transformOffsetY, transformOffsetY, 0).plus(source.yTween); // We don't need this to make our own animation work correctly,
      // but since we are a subclass of Move, and every Move requires
      // its sprite to have both initial and final bounds, we need to
      // this to remain protocol-compatible across interruptions.

      this.sprite.endRelativeTo(source.sprite);

      while (!this.xTween.done || !this.yTween.done) {
        sprite.translate(this.xTween.currentValue - sprite.transform.tx, this.yTween.currentValue - sprite.transform.ty);
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.Follow = Follow;
});