define('ember-form-for/components/form-fields/checkbox-group', ['exports', 'ember-form-for/templates/components/form-fields/checkbox-group'], function (exports, _checkboxGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const Component = Ember.Component,
        get = Ember.get;


  const CheckboxGroupComponent = Component.extend({
    tagName: '',
    layout: _checkboxGroup.default,

    actions: {
      updateSelection(value, object, propertyName, include) {
        let selection = get(object, propertyName);
        if (include && !selection.includes(value)) {
          selection.pushObject(value);
        } else {
          selection.removeObject(value);
        }

        if (this.get('update') !== undefined) {
          this.get('update')(object, propertyName, selection);
        }
      }
    }
  });

  CheckboxGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = CheckboxGroupComponent;
});