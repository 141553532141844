define("ember-power-select/components/power-select-multiple", ["exports", "ember-power-select/templates/components/power-select-multiple", "ember-power-select/utils/computed-fallback-if-undefined"], function (_exports, _powerSelectMultiple, _computedFallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _powerSelectMultiple.default,
    // Config
    triggerComponent: (0, _computedFallbackIfUndefined.default)('power-select-multiple/trigger'),
    beforeOptionsComponent: (0, _computedFallbackIfUndefined.default)(null),
    // CPs
    concatenatedTriggerClass: Ember.computed('triggerClass', function () {
      var classes = ['ember-power-select-multiple-trigger'];

      if (this.get('triggerClass')) {
        classes.push(this.get('triggerClass'));
      }

      return classes.join(' ');
    }),
    selected: Ember.computed({
      get() {
        return [];
      },

      set(_, v) {
        if (v === null || v === undefined) {
          return [];
        }

        return v;
      }

    }),
    computedTabIndex: Ember.computed('tabindex', 'searchEnabled', 'triggerComponent', function () {
      if (this.get('triggerComponent') === 'power-select-multiple/trigger' && this.get('searchEnabled') !== false) {
        return '-1';
      } else {
        return this.get('tabindex');
      }
    }),
    // Actions
    actions: {
      handleOpen(select, e) {
        var action = this.get('onopen');

        if (action && action(select, e) === false) {
          return false;
        }

        this.focusInput(select);
      },

      handleFocus(select, e) {
        var action = this.get('onfocus');

        if (action) {
          action(select, e);
        }

        this.focusInput(select);
      },

      handleKeydown(select, e) {
        var action = this.get('onkeydown');

        if (action && action(select, e) === false) {
          e.stopPropagation();
          return false;
        }

        if (e.keyCode === 13 && select.isOpen) {
          e.stopPropagation();

          if (select.highlighted !== undefined) {
            if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
              select.actions.choose(select.highlighted, e);
              return false;
            } else {
              select.actions.close(e);
              return false;
            }
          } else {
            select.actions.close(e);
            return false;
          }
        }
      },

      buildSelection(option, select) {
        var newSelection = (select.selected || []).slice(0);
        var idx = -1;

        for (var i = 0; i < newSelection.length; i++) {
          if (Ember.isEqual(newSelection[i], option)) {
            idx = i;
            break;
          }
        }

        if (idx > -1) {
          newSelection.splice(idx, 1);
        } else {
          newSelection.push(option);
        }

        return newSelection;
      }

    },

    // Methods
    focusInput(select) {
      if (select) {
        var input = document.querySelector("#ember-power-select-trigger-multiple-input-".concat(select.uniqueId));

        if (input) {
          input.focus();
        }
      }
    }

  });

  _exports.default = _default;
});