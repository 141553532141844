define("ember-table/-private/utils/computed", ["exports", "ember-table/-private/utils/observer"], function (_exports, _observer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicAlias = void 0;
  var PROPERTIES = new WeakMap();

  function findOrCreatePropertyInstance(propertyClass, context, key) {
    if (!PROPERTIES.has(context)) {
      PROPERTIES.set(context, {});
    }

    var propertiesForContext = PROPERTIES.get(context);
    var property = propertiesForContext[key];

    if (property) {
      return property;
    }

    property = propertyClass.create({
      _key: key,
      _context: context
    });
    var originalWillDestroy = context.willDestroy; // HACK: wrap around the original willDestroy to destroy our property as well

    context.willDestroy = () => {
      property.destroy();
      originalWillDestroy.apply(context, arguments);
    };

    propertiesForContext[key] = property;
    return property;
  }

  var ClassBasedComputedProperty = Ember.Object.extend({
    _context: null,
    _key: null,
    _computedFunction: null,
    _dependencies: null,

    init() {
      this._super(...arguments);

      this._redefineProperty();
    },

    // eslint-disable-next-line
    _contentDidChange: (0, _observer.observer)('_content', function () {
      if (!this._isUpdating && !this._context.isDestroyed && !this._context.isDestroying) {
        this._context.notifyPropertyChange(this._key);
      }
    }),

    _redefineProperty() {
      var dependencies = this.get('_dependencies');
      var isDynamicList = this.get('_isDynamicList');

      var computed = this._computedFunction(...dependencies.map((d, i) => isDynamicList[i] ? this.get("_context.".concat(d)) : "_context.".concat(d)));

      Ember.defineProperty(this, '_content', computed);
    },

    _get() {
      return this.get('_content');
    },

    _set(key, value) {
      this._isUpdating = true;
      this.set('_content', value);
      this._isUpdating = false;
      return this._get();
    }

  });

  function classComputedProperty(isDynamicList, computedFunction) {
    return function () {
      for (var _len = arguments.length, dependencies = new Array(_len), _key = 0; _key < _len; _key++) {
        dependencies[_key] = arguments[_key];
      }

      var extension = {
        _computedFunction: computedFunction,
        _isDynamicList: isDynamicList,
        _dependencies: dependencies
      };
      dependencies.forEach((dep, index) => {
        if (isDynamicList[index] === true) {
          // eslint-disable-next-line
          extension["".concat(dep, "DidChange")] = (0, _observer.observer)("_context.".concat(dep), function () {
            this._redefineProperty();
          });
        }
      });
      var klass = ClassBasedComputedProperty.extend(extension);
      return Ember.computed(...dependencies, {
        get(key) {
          var property = findOrCreatePropertyInstance(klass, this, key);
          return property._get();
        },

        set(key, value) {
          var property = findOrCreatePropertyInstance(klass, this, key);
          return property._set(key, value);
        }

      });
    };
  }

  var dynamicAlias = classComputedProperty([false, true], function () {
    for (var _len2 = arguments.length, segments = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      segments[_key2] = arguments[_key2];
    }

    if (segments.every(s => typeof s === 'string')) {
      return Ember.computed.alias(segments.join('.'));
    } else {
      return null;
    }
  });
  _exports.dynamicAlias = dynamicAlias;
});