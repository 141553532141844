define("bp-ember-components/components/x-date/month", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{moment-format this.value "MMMM"}}
  */
  {
    id: "ciIRyAN8",
    block: "{\"symbols\":[],\"statements\":[[1,[28,\"moment-format\",[[23,0,[\"value\"]],\"MMMM\"],null],false]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/x-date/month.hbs"
    }
  });

  var Month = (_dec = (0, _component.tagName)("span"), _dec(_class = class Month extends Ember.Component {}) || _class);
  _exports.default = Month;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Month);
});