define("bp-ember-components/utils/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCurrency;

  function formatCurrency(owner, value) {
    return owner.intl.formatNumber(value, {
      style: "currency",
      currency: "CZK"
    }).replace(/\s/, " ");
  }
});