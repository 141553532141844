define("ember-validated-form/components/validated-input", ["exports", "ember-validated-form/templates/components/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component wraps form inputs.
   *
   * It can be used in a two-way-binding style like
   * {{validated-input model=model name='firstName'}} (model will be updated)
   *
   * or in a one-way-binding style
   * {{validated-input model=model name='firstName' on-update=(action "update"}}
   * (update action is called, model is not updated)
   */
  var _default = Ember.Component.extend({
    layout: _validatedInput.default,
    dirty: false,
    labelComponent: "validated-label",
    required: false,
    type: "text",
    validateBeforeSubmit: true,
    classNameBindings: ["dirty", "config.css.group", "validationClass"],

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, "_val", Ember.computed("value", "model.".concat(this.get("name")), "name", function () {
        return this.get("value") || this.get("model.".concat(this.get("name")));
      }));
    },

    inputId: Ember.computed("elementId", "name", function () {
      return "".concat(this.get("elementId"), "-input-").concat(this.get("name"));
    }),
    validationClass: Ember.computed("showError", function () {
      var errorClass = this.get("config.css.error") || "has-error";
      var validClass = this.get("config.css.valid") || "valid";
      var isDirty = this.get("dirty");
      return this.get("showError") ? errorClass : isDirty ? validClass : "";
    }),
    error: Ember.computed("model.error", function () {
      var error = this.get("model.error");
      return error ? error[this.get("name")] : null;
    }),
    isValid: Ember.computed("error", function () {
      return !this.get("error");
    }),
    firstError: Ember.computed("error", function () {
      return this.get("error.validation")[0];
    }),
    showError: Ember.computed("isValid", "validateBeforeSubmit", "dirty", "submitted", function () {
      if (!this.get("isValid")) {
        if (this.get("validateBeforeSubmit") && this.get("dirty")) {
          return true;
        }

        if (this.get("submitted")) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      setDirty() {
        this.set("dirty", true);
      },

      update(value) {
        if (this.get("on-update")) {
          this.get("on-update")(value, this.get("model"));
        } else {
          this.set("model.".concat(this.get("name")), value);
        }
      }

    }
  });

  _exports.default = _default;
});