define("bp-ember-components/components/sort-it", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{~#if this.isAsc~}}
    <i class="grey caret up icon"></i>
  {{~/if~}}
  {{~#if this.isDesc~}}
    <i class="grey caret down icon"></i>
  {{~/if~}}
  
  {{yield}}
  */
  {
    id: "YnES1t5M",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isAsc\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"grey caret up icon\"],[8],[9]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isDesc\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"grey caret down icon\"],[8],[9]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    meta: {
      moduleName: "bp-ember-components/components/sort-it.hbs"
    }
  });

  var SortIt = (_dec = (0, _component.tagName)("th"), _dec2 = (0, _component.classNames)("sort-it"), _dec3 = Ember.computed("current", "value"), _dec4 = Ember.computed("current", "value"), _dec(_class = _dec2(_class = (_class2 = class SortIt extends Ember.Component {
    click() {
      var val = this.value;
      var current = this.current;

      if (current === val) {
        val = "-".concat(val);
      }

      this.action(val);
    }

    get isAsc() {
      return this.current === this.value;
    }

    get isDesc() {
      var current = this.current;
      var value = this.value;
      return current === "-".concat(value);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "isAsc", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isAsc"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isDesc", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isDesc"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SortIt;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SortIt);
});